export default {
    publicRoutes: [
        '^/cookie-test',
        '^/able', // Partner signup TODO: Remove when ready
        '^/advertiser-disclosure',
        '^/app_home', // Needed? TODO: Remove when ready
        '^/app/dynamic', // IMPORTANT - Do Not Remove unless path changes
        '^/basecamp', // Partner signup TODO: Remove when ready
        '^/test',
        '^/e2e-test-app/test-borrower-attributes',
        '^/basic-info',
        '^/e2e-test-app/test-basic-info',
        '^/marketplace-app/basic-info',
        '^/marketplace-app/owner-info',
        '^/funding-needs',
        '^/identity',
        // '^/form', // Universal App routes manage their own Auth/Allowance
        '^/login-error',
        '^/under-review',
        '^/bluevine', // Partner signup TODO: Remove when ready
        '^/bondstreet', // Partner signup TODO: Remove when ready
        '^/bookly', // Partner signup TODO: Remove when ready
        '^/business-credit-cards',
        '^/capitalpro', // Partner signup TODO: Remove when ready
        '^/credibility-capital', // Partner signup TODO: Remove when ready
        '^/credit-card-widget', // Credit card widget
        '^/sba-application-widget', // SBA Application widget
        '^/credit-gathering-authorization',
        '^/fundbox', // Partner signup TODO: Remove when ready
        '^/godaddy/adagility', // Partner signup TODO: Remove when ready
        '^/healthcheck',
        '^/home', // Needed? TODO: Remove when ready
        '^/intelligent-lending$',
        '^/lender-profile',
        '^/lenders',
        '^/lendingclub', // Partner signup TODO: Remove when ready
        '^/lendio-review',
        '^/lendvantage', // Partner signup TODO: Remove when ready
        '^/loanry', // Partner signup TODO: Remove when ready
        '^/login', // IMPORTANT - Do Not Remove unless path changes
        '^/moneysavingpro', // Partner signup TODO: Remove when ready
        '^/nav',
        '^/owner-info$', // Owner info sub-paths should not be public.
        '^/owner-info/$', // Owner info sub-paths should not be public, but allow a trailing slash
        '^/rapidadvance', // Partner signup TODO: Remove when ready
        '^/reset', // IMPORTANT - Do Not Remove unless path changes
        '^/rollbar',
        '^/seekbusinesscapital', // Partner signup TODO: Remove when ready
        '^/signup-staples', // Partner signup TODO: Remove when ready
        '^/signup', // IMPORTANT - Do Not Remove unless path changes
        '^/sproutfinancial', // Partner signup TODO: Remove when ready
        '^/staples-advantage', // Partner signup TODO: Remove when ready
        '^/staples-business-line-of-credit',
        '^/staples-business-loans', // Partner signup TODO: Remove when ready
        '^/staples-find-an-investor', // Partner signup TODO: Remove when ready
        '^/staples-loan-for-business', // Partner signup TODO: Remove when ready
        '^/staples-loan-for-small-business', // Partner signup TODO: Remove when ready
        '^/staples-loans-for-startup', // Partner signup TODO: Remove when ready
        '^/staples-small-business-line-of-credit', // Partner signup TODO: Remove when ready
        '^/staples-small-business-startup-loan', // Partner signup TODO: Remove when ready
        '^/staples-startup-business-loan', // Partner signup TODO: Remove when ready
        '^/staples-startup-small-business-loan', // Partner signup TODO: Remove when ready
        '^/suppliersuccess', // Partner signup TODO: Remove when ready
        '^/token', // IMPORTANT - Do Not Remove unless path changes
        '^/token-new',
        '^/top-lender-profile',
        '^/top-lenders',
        '^/townsquareinteractive', // Partner signup TODO: Remove when ready
        '^/upsstore', // Partner signup TODO: Remove when ready
        '^/404',
        '^/embedded/401',
        '^/qualifier',
        '^/waypoint-1',
        '^/waypoint-0',
        '^/sba-complete$',
        '^/sba-complete/basic-info$',
        '^/sba-complete/owner-info$',
        '^/document-upload-request/invite',
        '^/document-upload-request/invite-expired',
    ],
    // Determine if path is publicly accessible
    isPublicRoute: function(route) {
        if (route.matched && !route.matched.length) {
            // If the route doesn't exist -- its safe for it to be considered public and 404
            return true
        }
        for (let i = 0; i < this.publicRoutes.length; i++) {
            if (RegExp(this.publicRoutes[i]).test(route.path)) {
                return true
            }
        }
        return false
    },

    testOnlyRoutes: [
      '^/form', // Universal App routes manage their own Auth/Allowance
    ],

    isTestOnlyRoute: function(route) {
      for (let i = 0; i < this.testOnlyRoutes.length; i++) {
        if (RegExp(this.testOnlyRoutes[i]).test(route.path)) {
            return true
        }
      }
      return false
    },
}
