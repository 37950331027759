import env from '~/libs/env';
import IntercomChatService from "~/libs/intercom-chat-service";
import { useBorrowerStore } from '~/store/borrower';
import { useRootStore } from '~/store/root';
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization';
import { useAppAnalyticsStore } from '~/store/app-analytics';
import { storeToRefs } from 'pinia';

/**
 * Injects $chatService instance into nuxt application.
 *
 * Currently, provides an instance of IntercomChatService for Embedded and marketplace.
 */
export default defineNuxtPlugin(async ({ $pinia, _route }) => {
  const nuxtApp = useNuxtApp()

  let chatService = {
    show() {},
    hide() {},
    open() {},
  };

  let chatEnabled = env('intercomChat') === 'true' || env('intercomChat') === true
  if (useAffiliateCustomizationStore($pinia).chatEnabled === false) {
    chatEnabled = false
  }

  if (chatEnabled) {
    chatService = new IntercomChatService()

    nuxtApp.hook('page:finish', () => {
      setChatMetaData($pinia);
    })
  }

  nuxtApp.provide('chatService', chatService)
})

const bootIntercom = async ($pinia) => {
  if (window.Intercom !== undefined) {
    const appAnalyticsStore = useAppAnalyticsStore($pinia)
    const user = await useRootStore($pinia).authUser
    // const user_id = user ? user.id : appAnalyticsStore.getLendioId // When public we will want to use the lendioId
    const borrower = await useBorrowerStore($pinia).getBorrower()
    await useBorrowerStore($pinia).getBorrowerExperiments()
    const borrowerExperimentsDetails = useBorrowerStore($pinia).borrowerExperimentsDetailed;
    await useBorrowerStore($pinia).getLenderUser()
    const lenderUser = await useBorrowerStore($pinia).lenderUser

    if (!user || !borrower) return

    // Since the intercom hash endpoint is now an auth-protected endpoint, move
    // the request after the user/borrower check, so tests pass, and we can secure
    // the hashing endpoint
    const user_id = user.id
    const intercom_hash = await useRootStore($pinia).getIntercomHash()

    if(!intercom_hash || !user_id) return

    const lastDeal = await useBorrowerStore($pinia).getLastDeal()

    /**
     * Developer Note:
     * Intercom('update', { key: val }) API method is alos available in the future to
     * easily update the user / borrower's values as they progress through an application.
     * https://developers.intercom.com/installing-intercom/web/methods/#intercomupdate
     */

    window.Intercom("boot", {
      messenger_disabled: false,
      api_base: "https://api-iam.intercom.io",
      app_id: env('intercomAppId'),
      alignment: 'right',
      horizontal_padding: 100,
      vertical_padding: 20,
      user_hash: intercom_hash,
      user_id: user_id,
      name: `${user.first} ${user.last}`, // Full name
      email: user.email,
      utm_campaign: user.marketingData?.campaign || '',
      utm_content: user.marketingData?.content || '',
      utm_medium: user.marketingData?.medium || '',
      utm_source: user.marketingData?.source || '',
      utm_term: user.marketingData?.term || '',
      tenant_id: user.tenantId,
      logins: user.logins,
      company: {
        assigned_rep: lenderUser.fullName,
        assigned_rep_email: lenderUser.email,
        borrower_id: borrower.id, // let's explicitly set a borrowerId in intercom to denote the company_id = borrower_id
        company_id: borrower.id,
        experiments: borrowerExperimentsDetails.map(({ experimentId, group, name }) => ('(' + experimentId + ')' + name)).join(', '),
        industry: borrower.industryName,
        is_test: borrower.isTest,
        last_deal_stage: lastDeal?.stage || '',
        last_deal_status: lastDeal?.status || '',
        mineral_group: borrower.mineralGroup,
        name: borrower.name,
        open_lender_request: borrower.hasOpenLenderRequest ? 'Yes' : 'No',
        phone: borrower.phone,
        stage: borrower.stage,
        status: borrower.status,
        timezone: borrower.timezone
      }
    })
  }
}

async function setChatMetaData($pinia) {
  await bootIntercom($pinia)
}
