import accounting from 'accounting'

const frequencyInMonth = {
  daily: 30.4167,
  semiweekly: 4.34524 * 2,
  weekly: 4.34524,
  biweekly: 4.34524 / 2,
  semimonthly: 2,
  monthly: 1,
  bimonthly: 0.5,
}

function getOfferAmount(offer) {
  const amount = offer.amount ? Number(offer.amount) : null;
  const payback = offer.payback ? Number(offer.payback) : null;

  if (amount != null && amount > 0) {
    return amount;
  }

  if (payback == null || Number.isNaN(payback) || payback <= 0) {
    return null;
  }

  const factorRate = offer.factor ? Number(offer.factor) : 0.0;
  if (factorRate >= 1) {
    return payback / factorRate;
  }

  const interestRate = offer.interestRate ? Number(offer.interestRate) : 0.0;
  if (interestRate > 0) {
    const paymentsPerMonth = frequencyInMonth[offer.paymentFrequency];
    if (!paymentsPerMonth) {
      return null;
    }
    const paymentsPerYear = paymentsPerMonth * 12;
    const ratePerPeriod = interestRate / 100 / paymentsPerYear;
    const paymentsCount = offer.numPayments;
    if (!paymentsCount && !offer.term) {
      return null;
    }
    const numPayments = paymentsCount || Math.floor(offer.term * paymentsPerMonth);
    const paymentAmount = payback / numPayments;
    return paymentAmount * (1 - Math.pow(1 + ratePerPeriod, -numPayments)) / ratePerPeriod;
  }

  return null;
}

function getPaybackAmount(offer) {
  const payback = offer.payback ? Number(offer.payback) : null;
  const amount = offer.amount ? Number(offer.amount) : null;

  if (payback != null && payback > 0 && payback >= amount) {
    return payback;
  }

  if (!amount || Number.isNaN(amount) || amount <= 0) {
    return null;
  }

  const factorRate = offer.factor ? Number(offer.factor) : 0.0;

  if (factorRate >= 1) {
    return amount * factorRate;
  }

  const paymentsPerMonth = frequencyInMonth[offer.paymentFrequency];
  if (!paymentsPerMonth) {
    return null;
  }
  const paymentsCount = offer.numPayments;
  if (!paymentsCount && !offer.term) {
    return null;
  }
  const numPayments = paymentsCount || Math.floor(offer.term * paymentsPerMonth);

  const interestRate = offer.interestRate ? Number(offer.interestRate) : 0.0;

  if (interestRate > 0) {
    const paymentsPerYear = paymentsPerMonth * 12;
    const ratePerPeriod = interestRate / 100 / paymentsPerYear;
    const paymentAmount = ratePerPeriod * amount / (1 - Math.pow(1 + ratePerPeriod, -numPayments));
    return paymentAmount * numPayments;
  }

  const paymentAmount = offer.paymentAmount ? Number(offer.paymentAmount) : null;

  if (paymentAmount != null && !Number.isNaN(paymentAmount)) {
    const paybackAmount = paymentAmount * numPayments;
    return paybackAmount > amount ? paybackAmount : null;
  }

  return null;
}

function getRate(offer, { offerAmount, paybackAmount } = {}) {
  const factor = offer.factor ? Number(offer.factor) : null;
  const interestRate = offer.interestRate ? Number(offer.interestRate) : null;

  if (factor != null && factor > 0) {
    return { type: 'Factor', value: factor.toString() }
  }
  if (interestRate != null && interestRate > 0) {
    return { type: 'Interest', value: `${interestRate}%` }
  }

  if (paybackAmount != null && offerAmount != null && offerAmount >= offerAmount) {
    return { type: 'Factor', value: accounting.formatNumber((paybackAmount / offerAmount), 4, '', '.') }
  }
}

function getAdvanceRate(offer) {
  const advanceRate = offer.advanceRate ? Number(offer.advanceRate) : null;

  return advanceRate != null && advanceRate > 0 ? `${advanceRate}%` : null;
}

function getConvertedTerms(offer) {
  const months = offer.term != null ? Number(offer.term) : null;
  if (!Number.isFinite(months)) {
    return null;
  }

  return `${months} ${months === 1 ? 'month' : 'months'}`;
}

function getPaymentAmountByPeriod(offer, { paybackAmount } = {}) {
  const paymentAmount = offer.paymentAmount ? Number(offer.paymentAmount) : null;

  if (paymentAmount != null && paymentAmount > 0) {
    return paymentAmount;
  }
  if (!paybackAmount || paybackAmount < 0) {
    return null;
  }

  const numPayments = offer.numPayments ? Number(offer.numPayments) : 0;
  if (numPayments > 0) {
    return paybackAmount / numPayments;
  }

  const termsInMonth = offer.term != null ? Number(offer.term) : null;
  if (termsInMonth == null || Number.isNaN(termsInMonth) || termsInMonth <= 0) {
    return null;
  }

  const multiplier = frequencyInMonth[offer.paymentFrequency];
  if (!multiplier) {
    return null;
  }

  let paymentsCount = Math.floor(termsInMonth * multiplier);
  if (paymentsCount === 0) {
    paymentsCount++;
  }
  return paybackAmount / paymentsCount;
}

function getPaymentDetails(offer, { paymentAmountByPeriod } = {}) {
  const moneyThreshold = 100;
  if (!offer.paymentFrequency) {
    return null;
  }
  const frequencyMap = {
    daily: 'day',
    semiweekly: 'sm',
    weekly: 'wk',
    biweekly: 'bw',
    semimonthly: 'sm',
    monthly: 'mo',
    bimonthly: 'bm',
  };
  const frequencyTerm = frequencyMap[offer.paymentFrequency];
  if (!frequencyTerm) {
    return null;
  }

  if (paymentAmountByPeriod > 0) {
    const amountText = paymentAmountByPeriod < moneyThreshold
      ? accounting.formatMoney(paymentAmountByPeriod, '$', 2)
      : accounting.formatMoney(paymentAmountByPeriod, '$', 0);
    return `${amountText}/${frequencyTerm}.`;
  }
  return null;
}

function getConvertedPaymentFrequency(offer) {
  const frequencyMap = {
    daily: 'Daily',
    semiweekly: 'Semi-Weekly',
    weekly: 'Weekly',
    biweekly: 'Bi-Weekly',
    semimonthly: 'Semi-Monthly',
    monthly: 'Monthly',
    bimonthly: 'Bi-Monthly',
  };

  return frequencyMap[offer.paymentFrequency] || null;
}

function getCostOfCapital(offerAmount, paybackAmount) {
  if (!Number.isFinite(offerAmount) || !Number.isFinite(paybackAmount)) return null;

  return paybackAmount - offerAmount;
}

function getMinPurchaseFee(offer) {
  const minPurchaseFee = offer.minPurchaseFee ? Number(offer.minPurchaseFee) : null;

  return minPurchaseFee != null && minPurchaseFee > 0 ? `${minPurchaseFee}%` : null;
}

function getPaymentTerm(offer) {
  const paymentTerm = offer.paymentTerm || ''
  if (!paymentTerm || !paymentTerm.trim()) {
    return null;
  }

  return paymentTerm.charAt(0).toUpperCase() + paymentTerm.slice(1)
}

export default {
  getOfferTotal(offer) {
    if (!offer || typeof offer !== 'object') {
      return {};
    }

    const offerAmount = getOfferAmount(offer);
    const paybackAmount = getPaybackAmount(offer);
    const paymentAmountByPeriod = getPaymentAmountByPeriod(offer, { paybackAmount })

    return {
      offerAmount,
      paybackAmount,
      rate: getRate(offer, { offerAmount, paybackAmount }),
      advanceRate: getAdvanceRate(offer),
      convertedTerms: getConvertedTerms(offer),
      paymentAmountByPeriod,
      paymentDetails: getPaymentDetails(offer, { paymentAmountByPeriod }),
      convertedPaymentFrequency: getConvertedPaymentFrequency(offer),
      costOfCapital: getCostOfCapital(offerAmount, paybackAmount),
      minPurchaseFee: getMinPurchaseFee(offer),
      paymentTerm: getPaymentTerm(offer),
    }
  }
}
