import { useRootStore } from '~/store/root'

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxtApp = useNuxtApp()
  const { $lendioCookies, $pinia } = nuxtApp
  const isEmbeddedRoute = to.fullPath.includes('embedded')
  const isEmbeddedCookie = $lendioCookies.get('isEmbedded')
  const isEmbedded = isEmbeddedCookie || isEmbeddedRoute
  const rootStore = useRootStore($pinia)

  if (import.meta.server || isEmbedded || rootStore.expiryTimeoutSet) {
    return
  }

  rootStore.setupTokenExpirationTimeout()
})
