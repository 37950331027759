import { normalizeLoanType } from '~/libs/loan-product-info'

function buildACHData(termSet) {
  return {
    amount: termSet.amount,
    originationFeePercent: termSet.originationFeePercent,
    originationFeeAmount: termSet.originationFeeAmount,
    factorRate: termSet.factorRate,
    payback: getPayback(termSet),
    netFundedAmount: getNetFundedAmount(termSet),
    variablePercentOfRevenue: termSet.variablePercentOfRevenue,
  }
}

function buildLOCData(termSet) {
  return {
    amount: termSet.initialDrawAmount,
    periodicRate: termSet.periodicRate,
    drawFeePercent: termSet.drawFeePercent,
    drawFeeAmount: termSet.drawFeeAmount,
    annualInterestRate: termSet.annualInterestRate,
    interestRate: getInterestRate(termSet),
    apr: termSet.apr,
    creditLimit: termSet.creditLimit,
  }
}

function buildTermData(termSet) {
  return {
    amount: termSet.amount,
    periodicRate: termSet.periodicRate,
    originationFeePercent: termSet.originationFeePercent,
    originationFeeAmount: termSet.originationFeeAmount,
    annualInterestRate: termSet.annualInterestRate,
    interestRate: getInterestRate(termSet),
    apr: termSet.apr,
    payback: getPayback(termSet),
    netFundedAmount: getNetFundedAmount(termSet),
  }
}

function buildSbaData(termSet) {
  return {
    amount: termSet.amount,
    periodicRate: termSet.periodicRate,
    originationFeePercent: termSet.originationFeePercent,
    originationFeeAmount: termSet.originationFeeAmount,
    annualInterestRate: termSet.annualInterestRate,
    interestRate: getInterestRate(termSet),
    apr: termSet.apr,
    payback: getPayback(termSet),
    netFundedAmount: getNetFundedAmount(termSet),
  }
}

function getInterestRate(term) {
  if (term.apr) {
    return null
  }
  if (term.annualInterestRate && Number.isFinite(parseFloat(term.annualInterestRate))) {
    return parseFloat(term.annualInterestRate)
  }
  if (term.periodsPerYear && term.periodicRate && Number.isFinite(parseFloat(term.periodicRate))) {
    const periodicRate = parseFloat(term.periodicRate)
    return (periodicRate * term.periodsPerYear).toFixed(2)
  }
  return null
}

function getPayback(term) {
  let amount = term.initialDrawAmount || term.amount
  if (!amount) {
    return null;
  }
  amount = parseFloat(amount)
  if (!amount || !Number.isFinite(amount)) {
    return null;
  }

  let payback = term.payback
  if (payback) {
    payback = parseFloat(payback)
  }

  if (payback && Number.isFinite(payback) && payback >= amount) {
    return payback;
  }

  if (term.apr) {
    return null
  }

  const factorRate = Number(term.factorRate)
  // calculate payback only for valid factor rate
  if (factorRate < 1 && factorRate >= 2) {
    return null;
  }
  return amount * factorRate
}

function getNetFundedAmount(term) {
  let netFundedAmount = term.disbursement ? parseFloat(term.disbursement) : null;
  if (netFundedAmount && Number.isFinite(netFundedAmount)) {
    return netFundedAmount
  }

  let amount = term.initialDrawAmount || term.amount
  if (!amount) {
    return null;
  }
  amount = parseFloat(amount)
  if (!amount || !Number.isFinite(amount)) {
    return null;
  }

  let originationFeeAmount = term.drawFeeAmount || term.originationFeeAmount
  if (originationFeeAmount) {
    originationFeeAmount = parseFloat(originationFeeAmount)

    if (originationFeeAmount && Number.isFinite(originationFeeAmount) && originationFeeAmount < amount) {
      return amount - originationFeeAmount
    }
  }

  let originationFeePercent = term.drawFeePercent || term.originationFeePercent
  if (originationFeePercent) {
    originationFeePercent = parseFloat(originationFeePercent)

    if (originationFeePercent && originationFeePercent > 0 && originationFeePercent < 100) {
      originationFeeAmount = parseFloat((amount * (originationFeePercent / 100)).toFixed(2))
      return amount - originationFeeAmount
    }
  }

  return null
}


export function extendOption(type, termSet) {
  const data = termSet || {}
  const normalizedType = normalizeLoanType(type)
  const extendMap = {
    ach: buildACHData,
    loc: buildLOCData,
    term: buildTermData,
    sba: buildSbaData,
  }

  return extendMap?.[normalizedType](data) ?? {}
}
