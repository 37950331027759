export default function buildRollbarConfig($config) {
  return {
    enabled: $config.enableRollbar,
    accessToken: process.server ? $config.rollbarTokenServer : $config.rollbarTokenClient,
    captureUncaught: true,
    captureUnhandledRejections: true,
    reportLevel: $config.rollbarReportLevel,
    scrubFields: ['at', 'Authorization'],
    payload: {
      environment: $config.environment === 'LIVE' ? 'production' : $config.environment,
      client: {
        javascript: {
          source_map_enabled: true, // true by default
          code_version: $config.codeVersion,
          // ---------------------------------------
          // Optionally have Rollbar guess which frames the error was
          // thrown from when the browser does not provide line
          // and column numbers.
          guess_uncaught_frames: true
        }
      },
      custom: {
        tenant: $config.tenantName ? $config.tenantName : 'Lendio'
      }
    },
    onSendCallback: function (isUncaught, args, payload) {
      try {
        payload.fullstorySessionUrl = window.FS_launch.getCurrentSessionURL(true) || null;
      } catch (e) {
        return; // do nothing
      }
    }
  }
}
