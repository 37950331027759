import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import get from 'lodash/get'
import env from '~/libs/env'

export const useLoanProductLenderStore = defineStore('loanProductLender', () => {
  const nuxtApp = useNuxtApp();
  const { $axios } = nuxtApp;

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
  */

  const subscribedSaasFeatures = ref([]);
  const contractSigningPolicy = ref(null);
  const loanProductLenderLoaded = ref(false);

    /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
  */

  const hasContractSigningPolicy = computed(() => {
    return contractSigningPolicy.value != null
  });

  const hasInstantContracts = computed(() => {
    const subscription = subscribedSaasFeatures.value.find(
      (x) => x.feature?.alias === 'instantContracts'
    )
    return !!subscription
  });

  const supportAdvDocumentMgmt = computed(() => {
    const subscription = subscribedSaasFeatures.value.find(
      (x) => x.feature?.alias === 'advDocMgmt'
    )
    return !!subscription
  })

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
  */
  async function getLoanProductLenderInfo(dealId) {
    const url = `${env('apiUrl')}/deal/${dealId}/loan-product-lender`
    const response = await $axios.get(url).catch((e) => {
      log.error(e.message, e)
    })
    subscribedSaasFeatures.value = get(response, 'data.data.saasFeatures')
    contractSigningPolicy.value = get(response, 'data.data.signingPolicy')
    loanProductLenderLoaded.value = true;
  }

  return {
    // STATE
    subscribedSaasFeatures,
    contractSigningPolicy,
    loanProductLenderLoaded,

    // GETTERS
    hasContractSigningPolicy,
    hasInstantContracts,
    supportAdvDocumentMgmt,

    // ACTIONS
    getLoanProductLenderInfo
  }
})
