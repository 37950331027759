import env from '~/libs/env'

/**
 * ApplicationExperienceService
 *
 * Facilitates api calls for Application experience, including application configs and navigation.
 *
 * Requires a store passed so it has access to the correct `$axios` in context
 * for any XHR requests. Helps manage the interface of saving answers between
 * local storage and the actual service.
 */
export class ApplicationExperienceService {
    constructor($axios, $axiosWithRetry) {
      this.$axios = $axios
      this.$axiosWithRetry = $axiosWithRetry
    }

    /**
     * Returns ApplicationConfig for given ApplicationConfigEntrypoint identifier.
     * Only includes data for the NEXT route you're visiting
     *
     *
     * @returns {
     *  applicationConfig: ApplicationConfig,
     *  navigation: Navigation,
     *  submitSections: SubmitSections
     * }
     */
    async getExperienceByIdentifierAndSlug({ identifier, slug = '', borrowerId, tenantId, direction, anonymousId = null }) {
      const url = borrowerId
        ? `${env('apiUrl')}/application-experience/borrower/${borrowerId}/identifier/${identifier}/tenant/${tenantId}`
        : `${env('apiUrl')}/application-experience/identifier/${identifier}/tenant/${tenantId}`

      let error = null
      const params = borrowerId ? { slug, direction } : { slug, direction, anonymousId }
      const response = await this.$axiosWithRetry.get(url, { params }).catch(err => error = err)

      return { data: response?.data?.data ?? null, error }
    }

    async getEmbeddedAppExperienceCompleteStatus({ borrowerId }) {
      const url = `${env('apiUrl')}/borrower/${borrowerId}/embedded/get-app-experience-complete-status`

      let error = null
      const params = { borrowerId }
      const response = await this.$axiosWithRetry.get(url, { params }).catch(err => error = err)

      return { data: response?.data?.data ?? null, error }
    }

}

export default {
  ApplicationExperienceService
}
