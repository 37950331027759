export default function (key, $config) {
  let env
  if ($config) {
    env = $config.public
  } else {
    env = process.client ? window.env : global.env
  }

  const isProd = env.environment == 'production' || env.environment == 'LIVE';

  if (env.debugEnabled === 'true' && !isProd) {
    console.log(`Environment Variable ${key} accessed:`, env[key])
  }
  return env[key] || env.public && env.public[key] || null
}
