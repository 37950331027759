import axios from 'axios';
import axiosRetry from 'axios-retry';

function axiosSetup() {
  const axiosWithRetry = axios.create();
  axiosRetry(axiosWithRetry, { retries: 4, retryDelay: (retryCount, error) => {
    return axiosRetry.exponentialDelay(retryCount, error, 200);
  }});
  return {
    provide: {
      axios: axios.create(),
      axiosWithRetry
    }
  }
}

export default defineNuxtPlugin ({
  name: 'axiosSetup',
  setup: axiosSetup
})