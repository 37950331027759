import { defineStore } from 'pinia'
import get from 'lodash/get'

import { MBOX_DETAILS } from '~/libs/adobe-target'
import AnalyticsService from '~/libs/analytics-service'
import * as documentHelpers from '~/libs/document-helper'
import env from '~/libs/env'
import {
  alwaysDocuments, businessStartToMonthsInBusiness
} from '~/libs/increment-route-helpers'

import { useAppAnalyticsStore } from '~/store/app-analytics'
import { useBorrowerStore } from '~/store/borrower'
import { useDealsStore } from '~/store/deals'
import { useExperienceStore } from '~/store/experience'
import { useProgressStore } from '~/store/progress'
import { useQuestionsStore } from '~/store/questions'
import { useRootStore } from '~/store/root'
import { useUserStore } from '~/store/user'
import appNameFromRoute from '~/libs/app-name-from-route'
import { useApplicationConfigStore } from './application-config'

const monthsBack = 3

export const useDocumentsStore = defineStore('document', () => {
  const nuxtApp = useNuxtApp()
  const route = useRoute()
  const { $axios, $lendioCookies } = nuxtApp

  const appAnalyticsStore = useAppAnalyticsStore()
  const borrowerStore = useBorrowerStore()
  const experienceStore = useExperienceStore()
  const progressStore = useProgressStore()
  const questionsStore = useQuestionsStore()
  const rootStore = useRootStore()
  const userStore = useUserStore()
  const dealsStore = useDealsStore()
  const appConfigStore = useApplicationConfigStore()

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/
  const sbaTaxReturnPeriod = ref(documentHelpers.sbaTaxReturnPeriod())
  const hasExistingDebt = ref(true)
  const pendingUploads = ref([])
  const uploadComplete = ref(false)
  const uploadError = ref(null)
  const shareAccessError = ref(null)
  const documents = ref([])
  const loaded = ref(false)
  const stipsLoaded = ref(false)
  const bankStatementMonthsBack = ref(monthsBack)
  const manualLanding = ref(false)
  const stipulations = ref([])
  const additionalDocumentPills = ref([
    {
      name: 'Voided Check',
      offersText: 'Upload a voided check',
      accept: documentHelpers.dlFileTypes,
      meta: {
        category: 'voidedCheck',
        _localId: documentHelpers.generateId(),
        formattedName: 'Voided Check'
      }
    },
    {
      name: 'Proof of Ownership',
      offersText: 'Upload proof of ownership',
      helpText: `
      <p>Please provide any one of the following documents as proof of business ownership</p>

      <h5>Schedule K-1</h5>
      <p>IRS form used by LLC and Corporations to file taxes. Owners of the business must be listed on this form. Funding providers use this document to verify ownership of LLC and corporation type businesses.</p>

      <h5>Schedule C</h5>
      <p>IRS form used by sole proprietors to file taxes, owners of the business must be listed on this form. Funding providers use this document to verify ownership of sole proprietor type businesses.</p>

      <h5>Articles of Incorporation</h5>
      <p>Corporations are required to file Articles of Incorporation. These documents list the owners of the corporation. Funding providers sometimes are able to use these documents to verify ownership of a corporation.</p>

      <h5>Business License</h5>
      <p>Some lenders will use a business license as long as the license has the name of the business, the owner's name(s) and the address of the business.</p>
      `,
      meta: {
        category: 'ownershipVerification',
        _localId: documentHelpers.generateId(),
        formattedName: `Ownership Verification`
      }
    },
    {
      name: 'Month-to-Date Transactions',
      offersText: 'Upload month to date transactions',
      accept: 'application/pdf',
      fileText: 'PDF',
      helpText: `
      <p>A Month-to-Date (MTD) transaction report is a summary of transactions in your business bank account from the beginning of the current month to the present day.</p>

      <h5>General Instructions</h5>
      <p>The MTD transaction report varies from bank to bank. Here are some guidelines that may help you find and generate this summary:</p>

      <ol style="padding-left:60px">
        <li>Log in to your bank's online portal.</li>
        <li>Locate a “Transactions” or “Summary” page. This is often under the “Accounts” section.</li>
        <li>Filter the transactions by date. There may be a “Current Month” option, or a “Custom” option that will allow you to select the starting and ending dates.</li>
        <li>Save the report as a PDF.</li>
      </ol>

      <p>Reach out to a Lendio representative if you are unable to create a MTD Transaction Report. We will be happy to assist you.</p>
      `,
      meta: documentHelpers.formatMTDMeta(new Date())
    }
  ])
  const otherDocumentPills = ref([
    {
      name: 'Upload Document',
      meta: {
        _localId: documentHelpers.generateId(),
        category: 'other'
      }
    }
  ])
  const isShareAccessLinkFormVisible = ref(false)

/*
 ██████  ███████ ████████ ████████ ███████ ██████  ███████
██       ██         ██       ██    ██      ██   ██ ██
██   ███ █████      ██       ██    █████   ██████  ███████
██    ██ ██         ██       ██    ██      ██   ██      ██
 ██████  ███████    ██       ██    ███████ ██   ██ ███████
GETTERS
*/

  const uploadedDocuments = computed(() => {
    return documents.value
  })

  const requiredDocPills = computed(() => {
    return documentHelpers.getBankStatementPills(
      get(borrowerStore, 'finicityStatementsCount')
    )
  })

  const requiredDocPillsManual = computed(() => {
    return documentHelpers.getBankStatementPills(
      get(borrowerStore, 'bankStatementsRequiredCount')
    )
  })

  const requirements = computed(() => {
    return requiredDocPills.value.map(mapRequirements)
  })

  const requirementsManual = computed(() => {
    return requiredDocPillsManual.value.map(mapRequirements)
  })

  const sbaCompleteDocuments = computed(() => {
    const categories = [
      'balanceSheet',
      'businessTaxReturns',
      'debtSchedule',
      'personalTaxReturns',
      'pLCurrentYear',
    ];

    // get borrowerApplication for current app type
    const borrowerApplication = progressStore.lastBorrowerApplicationForCurrentApp

    // parse application start and end
    let start = null
    let end = null
    if (borrowerApplication) {
      start = borrowerApplication.started
        ? borrowerApplication.started * 1000
        : null

      end = borrowerApplication.completed
        ? borrowerApplication.completed * 1000
        : null
    }

    // return documents matching expected categories between app start and end
    return documents.value
      .filter(document => categories.includes(document.category))
      .filter(document => {
        const created = document.createdTimestamp * 1000
        return (
          (!start || start <= created)
          && (!end || created <= end)
        )
      })
  })

  const bankStatementsUploaded = computed(() => {
    return documents.value ? documents.value.filter((doc) => doc.category === 'bankStatement') : []
  })

  const finicityUploads = computed(() => {
    return bankStatementsUploaded.value ? bankStatementsUploaded.value.filter(
      (doc) => doc.uploadSource === 'finicity'
    ) : []
  })

  const _hasRequirements = (requirements = []) => {
    function _hasRequiredDocuments(requirementsArray) {
      return requirementsArray.document
    }
    if (requirements.length === 0) {
      return false
    }
    return requirements.every(_hasRequiredDocuments)
  }

  const hasRequiredBankStatements = computed(() => {
    return _hasRequirements(requirements.value)
  })

  const hasRequiredBankStatementsManual = computed(() => {
    return _hasRequirements(requirementsManual.value)
  })

  const hasRequiredBankStatementsUnsafe = computed(() => {
    const bankMonthsUploaded = bankStatementsUploaded.value.flatMap(upload => upload.months);
    const requiredBankMonths = requirements.value.flatMap( req => req.meta.months );
    return requiredBankMonths.every( reqMonth => bankMonthsUploaded.includes(reqMonth));
  })

  /**
   * Parses the expected documents objects used by navListConfig to determine if required documents have been uploaded.
   * Requires the user to have fetched their documents.
   * @returns {({type: string, min: number?}[]) => boolean}
   */
  const hasOfTypes = computed(() => {
    return (expectedDocuments) => {
      return !expectedDocuments.some(({ type, min = 1 }) => {
        return (
          documents.value.filter(({ category }) => category == type).length <
          min
        )
      })
    }
  })

  const requiredBankStatements = computed(() => {
    return requirements.value
      .filter((req) => {
        return req.document
      })
      .map((req) => {
        return req.document
      })
  })

  const documentsPageAllowed = computed(() => {
    const appName = appNameFromRoute(route) ?? 'marketplace'

    if (appName === 'marketplace') {
      return experienceStore.isSilverOrHigher()
    }

    if (experienceStore.isDNQ) {
      return false
    }

    const borrowerMineralGroup = get(
      borrowerStore,
      'borrowerValues.borrowerMineralGroup.value'
    )
    //Show documents if in SBA app
    if(progressStore.isCurrentAppSBA){
      return true
    }
    const defaultTrue = alwaysDocuments(borrowerMineralGroup)
    if (defaultTrue) {
      return true
    } else {
      const businessStartDate = get(
        borrowerStore,
        'borrowerValues.business_start_date'
      )
      const monthsInBusiness =
        businessStartToMonthsInBusiness(businessStartDate)

      // TODO: Check matches store to see if the borrower should see docs
      const indexedAttributesBankStatement = get(
        questionsStore,
        "indexedAttributes['bank_statement']"
      )
      const currentExperimentName = experienceStore.currentExperimentName

      // If time in business is less than 3, or matches do not require docs, or automation borrower, skip over documents
      if (
        monthsInBusiness <= 3 ||
        !indexedAttributesBankStatement ||
        currentExperimentName === 'AUTOMATION'
      ) {
        return false
      }
      return true
    }
  })

  const additionalDocumentsUploaded = computed(() => {
    const additionalDocuments = [
      {
        name: 'Voided Check',
        offersText: 'Voided Check',
        meta: {
          category: 'voidedCheck',
          _localId: 'voidedCheck',
          formattedName: 'Voided Check'
        }
      },
      {
        name: `Driver's license front`,
        offersText: "Driver's license front",
        variantRegex: documentHelpers.idFrontRegex,
        meta: {
          category: 'driverLicense',
          _localId: 'driversLicenseFront',
          formattedName: `DL - ${get(
            rootStore,
            'authUser.first'
          )} ${get(rootStore, 'authUser.last')} - Front`
        }
      },
      {
        name: `Driver's license back`,
        offersText: `Driver's license back`,
        variantRegex: documentHelpers.idBackRegex,
        meta: {
          category: 'driverLicense',
          _localId: 'driversLicenseBack',
          formattedName: `DL - ${get(
            rootStore,
            'authUser.first'
          )} ${get(rootStore, 'authUser.last')} - Back`
        }
      }
    ]

    return additionalDocuments.map((requirement) => {
      let pendingUpload = documentHelpers.findPendingUploadForPill(
        pendingUploads.value,
        requirement
      )
      const document = pendingUpload
        ? {
            filename: pendingUpload.filenames[0]
          }
        : documentHelpers.findDocumentForPill(documents.value, requirement)

      return {
        ...requirement,
        document,
        progress: pendingUpload ? pendingUpload.progress : null,
        error: pendingUpload ? pendingUpload.error : null
      }
    })
  })

  const additional = computed(() => {
    let additionalDocuments = additionalDocumentPills.value

    const DLFront = {
      name: "Driver's License (Front)",
      offersText: "Upload driver's license front",
      variantRegex: documentHelpers.idFrontRegex,
      accept: documentHelpers.dlFileTypes,
      meta: {
        category: 'driverLicense',
        _localId: 'driversLicenseFront',
        formattedName: `DL - ${get(
          rootStore,
          'authUser.first'
        )} ${get(rootStore, 'authUser.last')} - Front`
      }
    }

    const DLBack = {
      name: "Driver's License (Back)",
      offersText: "Upload driver's license back",
      variantRegex: documentHelpers.idBackRegex,
      accept: documentHelpers.dlFileTypes,
      meta: {
        category: 'driverLicense',
        _localId: 'driversLicenseBack',
        formattedName: `DL - ${get(
          rootStore,
          'authUser.first'
        )} ${get(rootStore, 'authUser.last')} - Back`
      }
    }

    additionalDocuments = [DLFront, DLBack, ...additionalDocuments]

    return additionalDocuments.map((requirement) => {
      let pendingUpload = documentHelpers.findPendingUploadForPill(
        pendingUploads.value,
        requirement
      )
      const document = pendingUpload
        ? {
            filename: pendingUpload.filenames[0]
          }
        : documentHelpers.findDocumentForPill(documents.value, requirement)

      return {
        ...requirement,
        document,
        progress: pendingUpload ? pendingUpload.progress : null,
        error: pendingUpload ? pendingUpload.error : null
      }
    })
  })

  const additionalDocsActionItems = computed(() => {
    const additionalDocs = additional.value
    const filtered = documentHelpers.checkHasDocument(additionalDocs)

    return additionalDocs.length - filtered.length
  })

  const additionalDocsSubmitted = computed(() => {
    const additionalDocs = additional.value
    const filtered = documentHelpers.checkHasDocument(additionalDocs)

    return additionalDocs.length === filtered.length
  })

  const otherDocs = computed(() => {
    // filter out documents not uploaded during this session, as well as bank statement docs
    const uploadedDocPills = documents.value.filter((document) => {
      return document._localId && document.category !== 'bankStatement'
    })

    // merge document pills with pending uploads and uploaded files
    let docPills = otherDocumentPills.value.map((docPill) => {
      // if we have a document for this pill, display it
      const documentForRequirement = documentHelpers.findDocumentForPill(
        uploadedDocPills,
        docPill
      )
      if (documentForRequirement) {
        return {
          document: documentForRequirement
        }
      }
      // if we have a pending upload for this doc pill, display it
      const pendingUploadForRequirement =
        documentHelpers.findPendingUploadForPill(pendingUploads.value, docPill)
      if (pendingUploadForRequirement) {
        return {
          document: {
            filename: pendingUploadForRequirement.filenames[0]
          },
          meta: pendingUploadForRequirement.meta,
          progress: pendingUploadForRequirement.progress,
          error: pendingUploadForRequirement.error
        }
      }
      // otherwise just return the doc pill
      return docPill
    })
    return docPills
  })

  const taxReturnName = computed(() => (year, type, category) => {
    return `${year} ${
      type.charAt(0).toUpperCase() + type.slice(1)
    } Tax Return${category.includes('Extension') ? ' Extension' : ''}`
  })

  const additionalOwnerSbaTaxReturnsRequired = computed(() => (filingStatus, additionalOwnerFilingStatus) => {
    const primaryReturnsRequired =
      documentHelpers.sbaTaxReturnPeriodDocs(filingStatus)
    return documentHelpers.additionalOwnerSbaTaxReturnPeriodDocs(
      additionalOwnerFilingStatus,
      primaryReturnsRequired
    )
  })

  const sbaTaxReturnsRequired  = computed(() => (filingStatus) => {
    return documentHelpers.sbaTaxReturnPeriodDocs(filingStatus)
  })

  const neededSbaTaxReturns = computed(() => (type, docs, additionalOwnerNumber) => {
    const taxReturnCategories = [
      'personalTaxReturns',
      'personalTaxReturnExtension',
      'businessTaxReturns',
      'businessTaxReturnExtension'
    ]
    const uploadedDocPills = documents.value.filter((document) => {
      return taxReturnCategories.includes(document.category)
    })
    // merge document pills with pending uploads and uploaded files
    if (additionalOwnerNumber) {
      return Object.entries(docs)
        .map(([year, category]) => ({
          name: `Additional Owner #${additionalOwnerNumber} - ${taxReturnName.value(
            year,
            type,
            category
          )}`,
          accept: '.pdf',
          meta: {
            _localId: `${year}_addtOwner_${category}_${additionalOwnerNumber}`,
            category: category,
            formattedName: `Additional Owner #${additionalOwnerNumber} - ${taxReturnName.value(
              year,
              type,
              category
            )}`,
            years: [year]
          }
        }))
        .map((docPill) => {
          // if we have a document for this pill, display it
          const documentForRequirement = additionalOwnerNumber
            ? documentHelpers.findAddOwnerDocumentForRequirement(
                uploadedDocPills,
                docPill
              )
            : documentHelpers.findDocumentForRequirement(
                uploadedDocPills,
                docPill
              )
          if (documentForRequirement) {
            return {
              ...docPill,
              document: documentForRequirement
            }
          }
          // if we have a pending upload for this doc pill, display it
          const pendingUploadForRequirement =
            documentHelpers.findPendingUploadForPill(
              pendingUploads.value,
              docPill
            )
          if (pendingUploadForRequirement) {
            return {
              document: {
                filename: pendingUploadForRequirement.filenames[0]
              },
              meta: pendingUploadForRequirement.meta,
              progress: pendingUploadForRequirement.progress,
              error: pendingUploadForRequirement.error
            }
          }
          // otherwise just return the doc pill
          return docPill
        })
    } else {
      return Object.entries(docs)
        .map(([year, category]) => ({
          name: taxReturnName.value(
            year,
            type,
            category,
            additionalOwnerNumber
          ),
          accept: '.pdf',
          meta: {
            _localId: `${year}_${category}${
              additionalOwnerNumber ? additionalOwnerTag : ''
            }`,
            category: category,
            formattedName: taxReturnName.value(
              year,
              type,
              category,
              additionalOwnerNumber
            ),
            years: [year]
          }
        }))
        .map((docPill) => {
          // if we have a document for this pill, display it
          const documentForRequirement =
            documentHelpers.findDocumentForRequirement(
              uploadedDocPills,
              docPill
            )
          if (documentForRequirement) {
            return {
              ...docPill,
              document: documentForRequirement
            }
          }
          // if we have a pending upload for this doc pill, display it
          const pendingUploadForRequirement =
            documentHelpers.findPendingUploadForPill(
              pendingUploads.value,
              docPill
            )
          if (pendingUploadForRequirement) {
            return {
              document: {
                filename: pendingUploadForRequirement.filenames[0]
              },
              meta: pendingUploadForRequirement.meta,
              progress: pendingUploadForRequirement.progress,
              error: pendingUploadForRequirement.error
            }
          }
          // otherwise just return the doc pill
          return docPill
        })
    }
  })

  const sbaTaxReturns = computed(() => {
    const taxReturnCategories = ['businessTaxReturns', 'personalTaxReturns']
    const uploadedDocPills = documents.value.filter((document) => {
      return taxReturnCategories.includes(document.category)
    })
    // merge document pills with pending uploads and uploaded files
    let docPills = taxReturnCategories
      .flatMap((category) =>
        [2021, 2020].map((year) => ({
          name: `${year} ${
            category == 'personalTaxReturns' ? 'Personal' : 'Business'
          } Tax Return`,
          accept: '.pdf',
          meta: {
            _localId: `${year}_${category}`,
            category: category,
            formattedName: `${year} ${
              category == 'personalTaxReturns' ? 'Personal' : 'Business'
            } Tax Return`,
            years: [year]
          }
        }))
      )
      .map((docPill) => {
        // if we have a document for this pill, display it
        const documentForRequirement =
          documentHelpers.findDocumentForRequirement(
            uploadedDocPills,
            docPill
          )
        if (documentForRequirement) {
          return {
            ...docPill,
            document: documentForRequirement
          }
        }
        // if we have a pending upload for this doc pill, display it
        const pendingUploadForRequirement =
          documentHelpers.findPendingUploadForPill(
            pendingUploads.value,
            docPill
          )
        if (pendingUploadForRequirement) {
          return {
            document: {
              filename: pendingUploadForRequirement.filenames[0]
            },
            meta: pendingUploadForRequirement.meta,
            progress: pendingUploadForRequirement.progress,
            error: pendingUploadForRequirement.error
          }
        }
        // otherwise just return the doc pill
        return docPill
      })
    return docPills
  })

  const sbaTaxReturnsAdditionalOwner = computed(() => (owners) => {
    const taxReturnCategories = ['PersonalTaxReturns']
    const uploadedDocPills = documents.value.filter((document) => {
      return taxReturnCategories.includes(document.category)
    })
    return owners.map((owner, index) => {
      index += 2
      // merge document pills with pending uploads and uploaded files
      let docPills = taxReturnCategories
        .flatMap((category) =>
          [2021, 2020].map((year) => ({
            name: `Additional Owner #${index} -
          ${year} Personal Tax Return`,
            accept: '.pdf',
            meta: {
              _localId: `${year}_addtOwner_${category}_${index}`,
              category: category,
              formattedName: `Additional Owner ${index} - ${year} Personal Tax Return`,
              years: [year]
            }
          }))
        )
        .map((docPill) => {
          // if we have a document for this pill, display it
          const documentForRequirement =
            documentHelpers.findAddOwnerDocumentForRequirement(
              uploadedDocPills,
              docPill
            )
          if (documentForRequirement) {
            return {
              ...docPill,
              document: documentForRequirement
            }
          }
          // if we have a pending upload for this doc pill, display it
          const pendingUploadForRequirement =
            documentHelpers.findPendingUploadForPill(
              pendingUploads.value,
              docPill
            )
          if (pendingUploadForRequirement) {
            return {
              document: {
                filename: pendingUploadForRequirement.filenames[0]
              },
              meta: pendingUploadForRequirement.meta,
              progress: pendingUploadForRequirement.progress,
              error: pendingUploadForRequirement.error
            }
          }
          // otherwise just return the doc pill
          return docPill
        })
      return docPills
    })
  })

  const sbaProfitAndLossDocumentPills = computed(() => {
    const currentYear = new Date().getFullYear()
    return (showPreviousYear = false) => {
      const pLCategories = ['pLCurrentYear']
      const years = [currentYear]
      if (showPreviousYear) {
        pLCategories.push('pLPreviousYear')
        years.push(currentYear - 1)
      }
      const uploadedDocPills = uploadedDocuments.value.filter((document) => {
        return pLCategories.includes(document.category)
      })

      // merge document pills with pending uploads and uploaded files
      let docPills = years
        .map((year) => ({
          name:
            year === currentYear
              ? `${year} YTD Profit and Loss Statement`
              : `${year} Profit & Loss Statement`,
          accept: '.pdf',
          meta: {
            _localId:
              year === currentYear
                ? `${year}_pLCurrentYear`
                : `${year}_pLPreviousYear`,
            category:
              year === currentYear ? 'pLCurrentYear' : 'pLPreviousYear',
            formattedName:
              year === currentYear ? `YTD P&L` : `Previous Year P&L`,
            years: [year.toString()]
          }
        }))
        .map((docPill) => {
          // if we have a document for this pill, display it
          const documentForRequirement =
            documentHelpers.findDocumentForRequirement(
              uploadedDocPills,
              docPill
            )
          if (documentForRequirement) {
            return {
              ...docPill,
              document: documentForRequirement
            }
          }
          // if we have a pending upload for this doc pill, display it
          const pendingUploadForRequirement =
            documentHelpers.findPendingUploadForPill(
              pendingUploads.value,
              docPill
            )
          if (pendingUploadForRequirement) {
            return {
              document: {
                filename: pendingUploadForRequirement.filenames[0]
              },
              meta: pendingUploadForRequirement.meta,
              progress: pendingUploadForRequirement.progress,
              error: pendingUploadForRequirement.error
            }
          }
          // otherwise just return the doc pill
          return docPill
        })
      return docPills
    }
  })

  const sbaBalanceSheet = computed(() => {
    const categories = ['balanceSheet']
    const currentYear = new Date().getFullYear()
    const uploadedDocPills = documents.value.filter((document) => {
      return categories.includes(document.category)
    })
    // merge document pills with pending uploads and uploaded files
    let docPills = categories
      .flatMap((category) =>
        [currentYear].map((year) => ({
          name: `YTD Balance Sheet`,
          accept: '.pdf',
          meta: {
            _localId: `${year}_${category}`,
            category: category,
            formattedName: `YTD Balance Sheet`,
            years: [year]
          }
        }))
      )
      .map((docPill) => {
        // if we have a document for this pill, display it
        const documentForRequirement =
          documentHelpers.findDocumentForRequirement(
            uploadedDocPills,
            docPill
          )
        if (documentForRequirement) {
          return {
            ...docPill,
            document: documentForRequirement
          }
        }
        // if we have a pending upload for this doc pill, display it
        const pendingUploadForRequirement =
          documentHelpers.findPendingUploadForPill(
            pendingUploads.value,
            docPill
          )
        if (pendingUploadForRequirement) {
          return {
            document: {
              filename: pendingUploadForRequirement.filenames[0]
            },
            meta: pendingUploadForRequirement.meta,
            progress: pendingUploadForRequirement.progress,
            error: pendingUploadForRequirement.error
          }
        }
        // otherwise just return the doc pill
        return docPill
      })
    return docPills
  })

  const newestVoidedCheck = computed(() => {
    const docs = documents.value
      .filter((doc) => doc.category === 'voidedCheck')
      .sort((a, b) => a.modifiedTimestamp - b.modifiedTimestamp)
      .map((doc) => {
        return {
          name: 'Voided Check',
          accept: documentHelpers.dlFileTypes,
          meta: {
            _localId: documentHelpers.generateId(),
            category: doc.category
          },
          document: doc
        }
      })[0]
    return docs
  })

  const contractDocs = computed(() => {
    const contractCategories = ['contractUnsigned', 'contractSigned']
    return documents.value.filter(
      (doc) => contractCategories.indexOf(doc.category) >= 0
    )
  })

  const hasContractDocs = computed(() => {
    return contractDocs.value.length > 0
  })

  const contractSignedDocs = computed(() => {
    const contractSignedDocPills = documents.value
      .filter((document) => {
        return document.category === 'contractUnsigned' && document.dealId
      })
      .map((contract) => {
        const contractPill = {
          label: 'Signed contract',
          meta: {
            dealId: contract.dealId,
            category: 'contractSigned'
          }
        }
        const doc = documents.value.find((document) => {
          return (
            document.category === 'contractSigned' &&
            parseInt(document.dealId) === parseInt(contract.dealId)
          )
        })

        if (doc) {
          return {
            label: 'Signed contract',
            document: doc,
            meta: {
              dealId: contract.dealId,
              category: 'contractSigned'
            }
          }
        }

        // if we have a pending upload for this doc pill, display it
        const pendingUploadForRequirement = pendingUploads.value.find(
          (upload) => upload.meta.dealId === contractPill.meta.dealId
        )
        if (pendingUploadForRequirement) {
          return {
            document: {
              filename: pendingUploadForRequirement.filenames[0]
            },
            meta: {
              ...pendingUploadForRequirement.meta,
              dealId: contract.dealId
            },
            progress: pendingUploadForRequirement.progress,
            error: pendingUploadForRequirement.error
          }
        }
        return contractPill
      })
      .reduce((accumulator, value) => {
        accumulator[value.meta.dealId] = accumulator[value.meta.dealId]
          ? accumulator[value.meta.dealId].concat(value)
          : [value]
        return accumulator
      }, {})

    return contractSignedDocPills
  })

  const bankDocs = computed(() => {
    return documents.value.filter((doc) => doc.category === 'bankStatement')
  })

  const hasBankDocs = computed(() => {
    return bankDocs.value.length > 0
  })

  //Returns true if there are any documents that are older than 4 months associated with a borrowerconst hasOldBankDocs = computed(() => {
  const hasOldBankDocs = computed(() => {
    const oldBankDocs = requiredDocPills.value.filter(
      (doc) => doc.documentAge > 4
    )
    if (oldBankDocs.length > 0) {
      return true
    } else return false
  })

  const manualDocumentsRecommended = computed(() => {
    return appAnalyticsStore.hasTargetOffer(
      MBOX_DETAILS.MANUAL_DOCUMENTS_RECOMMENDED.NAME,
      'manualDocumentsRecommended'
    )
  })

  /**
   * Returns pill configs for stipulations hydrated with borrower document upload data
   *
   * @returns Array
   */
  const stips = computed(() => {
    const hydratedStipulations = documentHelpers.hydratedPillConfigsStipulations({
      documents: documents.value,
      pendingUploads: pendingUploads.value,
      stipulations: stipulations.value
    })
    return hydratedStipulations
  })

  const missingManualRequirements = computed(() => requirementsManual.value.filter((req) => !req.document))

  const advancedDocumentsSummary = computed(() => {
    const modules = appConfigStore.documentUploadRequestApplicationConfig?.modules;
    if (!modules) return {requiredDocs: null, fulfilledDocs: null};
    const fulfilledDocs = modules.reduce((fulfilledDocsCarry, module) => {
      const moduleDocuments = module.moduleDocuments;
      if (!moduleDocuments) return fulfilledDocsCarry;
      const requirementsCompletion = moduleDocuments.map((moduleDocument) => {
        const requirements = moduleDocument.requirements;
        return requirements.every((requirement) => requirement.document)
      })
      if (!requirementsCompletion.includes(false)) fulfilledDocsCarry++
      return fulfilledDocsCarry;
    }, 0);
    const requiredDocs = modules.length;
    return { requiredDocs, fulfilledDocs };
  })

  const advancedStipsComplete = computed(() => {
    return advancedDocumentsSummary.value?.requiredDocs &&
      advancedDocumentsSummary.value?.fulfilledDocs &&
      advancedDocumentsSummary.value.fulfilledDocs >= advancedDocumentsSummary.value.requiredDocs;
  })

/*
 █████   ██████ ████████ ██  ██████  ███    ██ ███████
██   ██ ██         ██    ██ ██    ██ ████   ██ ██
███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
██   ██  ██████    ██    ██  ██████  ██   ████ ███████
ACTIONS
*/

  const mapRequirements = (requirement) => {
    const pendingUpload = documentHelpers.findPendingUploadForPill(
      pendingUploads.value,
      requirement
    )
    const document = pendingUpload ? { filename: pendingUpload.filenames[0] }
      : documentHelpers.findDocumentForPill(documents.value, requirement)

    return {
      ...requirement,
      document,
      progress: pendingUpload ? pendingUpload.progress : null,
      error: pendingUpload ? pendingUpload.error : null
    }
  }

  function replaceBankStatements(bankStatements) {
    if (!bankStatements || !Array.isArray(bankStatements)) {
      return
    }

    const currentDocs = documents.value
    if (!currentDocs || !Array.isArray(currentDocs)) {
      documents.value = bankStatements
      return
    }

    const docsWithoutBankStatements = currentDocs.filter((doc) => doc.category !== 'bankStatement')

    documents.value = [
      ...docsWithoutBankStatements,
      ...bankStatements
    ]
  }

  async function getDocuments(refresh = false) {
    if (rootStore.pwfSignupInProgress) {
      return []
    }

    loaded.value = false
    if (documents.value.length && !refresh) {
      loaded.value = true
      return documents.value
    }

    // get borrower id
    const borrower = await borrowerStore.getBorrower()
    if (!borrower) return
    const res = await $axios.get(
      env('apiUrl') + '/document/borrower/' + borrower.id,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
    if (!res) {
      loaded.value = true
      return []
    }
    documents.value = res.data.data
    loaded.value = true

    return res.data.data
  }

  async function getStipulations(dealId, ignoreCompleted = true) {
    const res = await $axios.get(
      env('apiUrl') + '/deals/' + dealId + '/stipulations' + (ignoreCompleted ? '?exclude[]=completed' : ''),
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
    stipulations.value = res.data.data
    stipsLoaded.value = true;
  }

  function _removeDocument(requirement) {
    const index = documents.value.indexOf(requirement.document)
    if(requirement.meta?.document) {
      requirement.meta.document = null
    }

    if (index >= 0) {
      documents.value.splice(index, 1)
    } else {
      const pendingUpload = documentHelpers.findPendingUploadForPill(
        pendingUploads.value,
        requirement
      )
      const pendingDocIndex = pendingUploads.value.indexOf(pendingUpload)
      pendingUploads.value.splice(pendingDocIndex, 1)
    }
  }

  async function deleteDocument(requirement) {
    if (!requirement.document) {
      return
    }
    if (requirement.document.id) {
      return $axios.delete(
        env('apiUrl') + '/document/' + requirement.document.id,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      ).then(
        _removeDocument(requirement)
      )
    }
  }

  function _removeDocumentGeneric(document) {
    const index = documents.value.indexOf(document)
    if (index >= 0) {
      documents.value.splice(index, 1)
    }
  }

  async function genericDeleteDocument(document) {
    if (!document) {
      return
    }
    if (document.id) {
      return $axios.delete(env('apiUrl') + '/document/' + document.id, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(
        _removeDocumentGeneric(document)
      )
    }
  }

  // mock out uploadDocument
  function completeUpload({ pendingUpload, document }) {
    let pendingDocIndex = pendingUploads.value.indexOf(pendingUpload)
    pendingUploads.value.splice(pendingDocIndex, 1)
    // identify that this document was uploaded during this session

    document._localId = pendingUpload.meta._localId
    uploadComplete.value = pendingUpload.meta._localId
    this.addDocument(document)
    const borrowerId = get(
      borrowerStore,
      'borrower.borrowerId'
    )
    AnalyticsService.track('Document Uploaded', {
      category: document.category,
      borrowerId
    })
  }

  async function uploadDocument({ files, meta, simpleDocFlow = false }) {
    if (simpleDocFlow) {
      uploadComplete.value = false
      pendingUploads.value = []
      uploadError.value = null
    }
    const filenames = Array.from(files).map((file) => {
      return file.name
    })

    const pendingUpload = {
      filenames,
      meta,
      progress: 0
    }
    pendingUploads.value.push(pendingUpload)

    let fileSize = 0;
    // Go through all files and make sure they are within the max upload size
    Array.from(files).forEach((file) => {
      // We don't accept files larger than 67MB
      if (file.size >= 67000000) {
        pendingUpload.progress = null
        pendingUpload.error = 'Document file size is too large'
        uploadError.value = true
        fileSize = file.size
        return
      }
    })

    // If there is an error right here, it is because the file is too large
    if (pendingUpload.error) {
      const borrower = await borrowerStore.getBorrower();
      const errorBody = {
        'size': fileSize,
        'borrowerId': borrower.id,
        'error': 'FILE_SIZE_TOO_LARGE'
      }

      // This endpoint posts to a kinesis stream of document-upload-error
      $axios.post(`${env('apiUrl')}/document/upload-error`, JSON.stringify(errorBody), {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return
    }

    const documentUploadRequestInviteToken = $lendioCookies.get('documentUploadRequestInviteToken')
    const isGuest = documentUploadRequestInviteToken && !rootStore.isLoggedIn
    const Authorization = isGuest
      ? `lendio-jwt ${documentUploadRequestInviteToken}`
      : userStore.getAuthHeader({ useCookies: true, silent: false })

    if (!Authorization) {
      return
    }

    const formData = new FormData()

    // Append metaData
    if (meta) {
      for (let key in meta) {
        let val = meta[key]
        if (typeof val === 'object') {
          val = JSON.stringify(val)
        }
        formData.append(key, val)
      }
    }

    const isFileList = Object.prototype.toString.call(files) === '[object Array]' ||
      Object.prototype.toString.call(files) === '[object FileList]'

    // Append files
    const fileFieldName = 'file'
    if (isFileList) {
      Array
        .from(Array(files.length).keys())
        .map(key => {
          formData.append(fileFieldName, files[key], files[key].name)
        })
    } else {
      formData.append(fileFieldName, files, files.name)
    }

    const activeDealWithAcceptedOffer = dealsStore.deals.find(d => !['inactive', 'funded'].includes(d.stage) && d.acceptedOffer);
    if (activeDealWithAcceptedOffer) {
      formData.append('dealId', activeDealWithAcceptedOffer.id);
    }

    // guest uploads should always attach to deal
    // borrower uploads should only attach to deal if there is an active deal with an accepted offer
    if (activeDealWithAcceptedOffer || isGuest) {
      formData.append('attach', true);
    }

    // Axios Config
    var config = {
      onUploadProgress: function(progressEvent) {
        let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        let pendingDocIndex = pendingUploads.value.indexOf(pendingUpload)
        pendingUploads.value[pendingDocIndex].progress = percentCompleted
      },
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization
      }
    };

    return $axios.post(isGuest ? `${env('apiUrl')}/guest/document` : `${env('apiUrl')}/document`, formData, config)
      .then((res) => {
        const resDocument = get(res, 'data.data')
        this.completeUpload({
          pendingUpload,
          document: resDocument
        })
        return resDocument
      })
      .catch((err) => {
        const responseData = get(err, 'response.data', {})
        const errorMsg = responseData.message || responseData.data || err.error || err.message || 'Something went wrong'
        log.warning('Document Upload Error', { errorMsg, data: responseData, simpleDocFlow })
        if (simpleDocFlow) {
          const error = {
            _localId: pendingUpload.meta._localId,
            error: errorMsg
          }
          pendingUploads.value = []
          uploadError.value = error
        } else {
          const pendingDocIndex = pendingUploads.value.indexOf(pendingUpload)
          pendingUploads.value[pendingDocIndex].progress = null
          pendingUploads.value[pendingDocIndex].error = errorMsg + '. Please refresh and try again.'
          uploadError.value = true
        }
        return null
      });
  }

  async function authDownload({ documentId, params = {}, filename = '', newTab = true }) {
    const Authorization = userStore.getAuthHeader({ useCookies: true, silent: false })
    if (!Authorization) {
      return
    }

    const axiosConfig = {
      responseType: 'blob',
      headers: {
        Authorization
      },
      params: Object.assign({ stream: 1 }, params),
    }
    return $axios.get(`${env('apiUrl')}/document/${documentId}`, axiosConfig)
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(response.data)
        if (newTab) {
          window.open(downloadUrl)
        } else {
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        const responseData = get(err, 'response.data', {})
        const errorMsg =  responseData.message || responseData.data || err.error || err.message || 'Something went wrong'
        log.warning('Document Download Error', { errorMsg, data: responseData })
      });
  }

  function addOtherDocumentPill() {
    otherDocumentPills.value = [...otherDocumentPills.value, {
      name: 'Upload Document',
      meta: {
        _localId: documentHelpers.generateId(),
        category: 'other'
      }
    }]
  }

  function injectDocument(document) {
    const docIndex = documents.value.findIndex((doc) => {
      return document.id === doc.id
    })
    if (docIndex >= 0) {
      document._localId = documents.value[docIndex]._localId
      documents.value.splice(docIndex, 1, document)
    } else {
      this.addDocument(document)
    }
  }

  function addDocument(document, source = '') {
    documents.value.push(document)
    if (source === 'finicity') {
      const borrowerId = get(
        borrowerStore,
        'borrower.borrowerId'
      )
      AnalyticsService.track('Finicity - Statement Pulled', { borrowerId })
    }
  }

  function toggleShareAccessLinkForm(state = null) {
    if (state !== null) {
      isShareAccessLinkFormVisible.value = state
    } else {
      isShareAccessLinkFormVisible.value = !isShareAccessLinkFormVisible.value;
    }
  }

  async function sendSharedDocumentAccessEmailAddress(email) {
    // get borrower id
    const borrower = await borrowerStore.getBorrower()
    if (!borrower) return
    return $axios.post(`${env('apiUrl')}/borrower/${borrower.id}/document-upload-request/invite`, { email })
    .catch((err) => {
      setShareAccessError(true);
      log.error('Error sending SharedDocumentAccess email address to API', {
        error: {message: err.message, code: err.code, stack: err.stack}
      })
    });
  }

  function setShareAccessError(state = false) {
    shareAccessError.value = state || false
  }

  return {
    // STATE
    sbaTaxReturnPeriod,
    hasExistingDebt,
    pendingUploads,
    uploadComplete,
    uploadError,
    documents,
    loaded,
    bankStatementMonthsBack,
    manualLanding,
    stipulations,
    stipsLoaded,
    additionalDocumentPills,
    otherDocumentPills,
    isShareAccessLinkFormVisible,
    shareAccessError,

    // GETTERS
    uploadedDocuments,
    requirements,
    requirementsManual,
    requiredDocPills,
    requiredDocPillsManual,
    sbaCompleteDocuments,
    bankStatementsUploaded,
    finicityUploads,
    hasRequiredBankStatements,
    hasRequiredBankStatementsUnsafe,
    hasRequiredBankStatementsManual,
    hasOfTypes,
    requiredBankStatements,
    documentsPageAllowed,
    additionalDocumentsUploaded,
    additional,
    additionalDocsActionItems,
    additionalDocsSubmitted,
    otherDocs,
    taxReturnName,
    additionalOwnerSbaTaxReturnsRequired,
    sbaTaxReturnsRequired,
    neededSbaTaxReturns,
    sbaTaxReturns,
    sbaTaxReturnsAdditionalOwner,
    sbaProfitAndLossDocumentPills,
    sbaBalanceSheet,
    newestVoidedCheck,
    contractDocs,
    hasContractDocs,
    contractSignedDocs,
    hasBankDocs,
    bankDocs,
    hasOldBankDocs,
    manualDocumentsRecommended,
    stips,
    missingManualRequirements,
    advancedDocumentsSummary,
    advancedStipsComplete,

    // ACTIONS
    replaceBankStatements,
    mapRequirements,
    getDocuments,
    getStipulations,
    deleteDocument,
    genericDeleteDocument,
    completeUpload,
    uploadDocument,
    addOtherDocumentPill,
    injectDocument,
    addDocument,
    authDownload,
    toggleShareAccessLinkForm,
    sendSharedDocumentAccessEmailAddress,
    setShareAccessError,
  }
})
