import { defineStore } from 'pinia'
import { checkConfigBoolean as configBoolean}  from '~/libs/config-helper'

import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import { useApplicationConfigStore } from '~/store/application-config'
import { useBorrowerStore } from '~/store/borrower'
import { useCreditStore } from '~/store/credit'
import { useDebtScheduleStore } from '~/store/debt-schedule'
import { useDocumentsStore } from '~/store/documents'
import { useExperienceStore } from '~/store/experience'
import { useFundingNeedsStore } from '~/store/funding-needs'
import { useProgressStore } from '~/store/progress'
import { useQuestionsStore } from '~/store/questions'
import { useTenantStore } from '~/store/tenant'
import { useUserStore } from '~/store/user'
import { useSunriseFinicityStore } from '~/store/sunrise-finicity'

export const useNavListConfigStore = defineStore('navListConfig', () => {
  const experienceStore = useExperienceStore()
  const questionsStore = useQuestionsStore()
  const borrowerStore = useBorrowerStore()
  const documentsStore = useDocumentsStore()
  const tenantStore = useTenantStore()
  const fundingNeedsStore = useFundingNeedsStore()
  const userStore = useUserStore()
  const debtScheduleStore = useDebtScheduleStore()
  const progressStore = useProgressStore()
  const affiliateCustomizationStore = useAffiliateCustomizationStore()
  const creditStore = useCreditStore()
  const applicationConfigStore = useApplicationConfigStore()
  const finicityStore = useSunriseFinicityStore()

/*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
*/


/*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
*/

  const progress = computed(() => {
    return (getter) => progressStore[getter]
  })

  const applicationConfig = computed(() => {
    return (getter) => applicationConfigStore[getter]
  })

  const questions = computed(() => {
    return (getter) => questionsStore[getter]
  })

  const experience = computed(() => {
    return (getter) => experienceStore[getter]
  })

  const user = computed(() => {
    return (getter) => userStore[getter]
  })

  const document = computed(() => {
    return (getter) => documentsStore[getter]
  })

  const borrower = computed(() => {
    return (getter) => borrowerStore[getter]
  })

  const tenant = computed(() => {
    return (getter) => tenantStore[getter]
  })

  const finicity = computed(() => {
    return (getter) => finicityStore[getter]
  })

  const fundingNeeds = computed(() => {
      return (getter) => fundingNeedsStore[getter]
  })

  const useDebtSchedule = computed(() => {
    return (getter) => debtScheduleStore[getter]
  })

  const affiliateCustomizations = computed(() => {
    return (getter) => affiliateCustomizationStore[getter]
  })

  const credit = computed(() => {
    return (getter) => creditStore[getter]
  })

  const checkConfigBoolean = computed(() => {
    const context = {
      applicationConfig: applicationConfig.value,
      progress: progress.value,
      questions: questions.value,
      experience: experience.value,
      user: user.value,
      document: document.value,
      borrower: borrower.value,
      tenant: tenant.value,
      finicity: finicity.value,
      fundingNeeds: fundingNeeds.value,
      useDebtSchedule: useDebtSchedule.value,
      affiliateCustomizations: affiliateCustomizations.value,
      credit: credit.value
    }
    return configBoolean.bind(context)
  })
/*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
*/


  async function runRequiredActions(configValue) {
    const inverterRegExp = /^(\!?)(.+)$/
    let [storeId, action, ...params] = configValue
    const [_, invert, accessor] = inverterRegExp.exec(action)

    const func = this[storeId]
    if (typeof(func) !== 'function') {
      throw new Error(`${storeId} not defined in nav-list-config store.`)
    }

    let val = func(accessor)
    if (typeof(val) === 'function') {
      val = await val(...params)
    }
    return invert ? !val : val
  }

  function applicationConfigAction(action) {
    return applicationConfigStore[action]
  }

  function experienceAction(action) {
    return experienceStore[action]
  }

  function questionsAction(action) {
    return questionsStore[action]
  }

  function borrowerAction(action) {
    return borrowerStore[action]
  }

  function documentAction(action) {
    return documentsStore[action]
  }

  function finicityAction(action) {
    return finicityStore[action]
  }

  function fundingNeedsAction(action) {
    return fundingNeedsStore[action]
  }

  function userAction(action) {
    return userStore[action]
  }

  function debtScheduleAction(action) {
    return debtScheduleStore[action]
  }

  return {
    //GETTERS
    checkConfigBoolean,
    progress,
    applicationConfig,
    questions,
    experience,
    user,
    document,
    borrower,
    tenant,
    finicity,
    fundingNeeds,
    useDebtSchedule,
    affiliateCustomizations,
    credit,


    //ACTIONS
    runRequiredActions,
    applicationConfigAction,
    experienceAction,
    questionsAction,
    borrowerAction,
    documentAction,
    finicityAction,
    fundingNeedsAction,
    userAction,
    debtScheduleAction
  }

})
