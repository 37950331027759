import { format } from 'date-fns'
import { subMonths } from 'date-fns'
import get from 'lodash/get'

/**
 * Here's a breakdown of the regex in case you need to change it
 * * **^** Start at the beginning of the string
 * *  **?!** Negative lookahead: From the start position, begin trying to NOT match the capture group string
 * *  **- Back|- ID Back** The capture group strings.  Can be either '-Back' OR '- ID Back'
 * *  **.** Match against everything
 * *  **$** Until the end of the string
 * * **Meaning:**  Starting at the beginning of the string until you reach the end,
 * go through the string and consider it a match as long as you don't encounter the string '- Back' or '- ID Back'
*/
export const idFrontRegex = /^((?!- Back|- ID Back).)*$/

/** Try to match the string '- Back' or '- ID Back' */
export const idBackRegex = /- Back|- ID Back/

export const totalFileType = [
  '.bmp',
  '.csv',
  '.doc',
  '.docx',
  '.gif',
  '.htm',
  '.html',
  '.jpeg',
  '.jpg',
  '.odc',
  '.odp',
  '.odt',
  '.ots',
  '.p7s',
  '.pdf',
  '.png',
  '.ppt',
  '.pptx',
  '.svg',
  '.tif',
  '.tiff',
  '.tsv',
  '.txt',
  '.vcf',
  '.vcs',
  '.xls',
  '.xlsm',
  '.xlsx',
  '.xps'
];

export const dlFileTypes = '.bmp, .gif, .jpeg, .jpg, .pdf, .png, .svg, .tif, .tiff'
export const coreFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']
export const additionalDocUploadFileTypes = coreFileTypes.concat(['image/bmp', 'image/gif', 'image/tif', 'image/tiff'])

export const satisfiesTimeClassification =  (document, requirement) => {
  let units
  if (requirement.meta.months) {
    units = 'months'
  } else if (requirement.meta.years) {
    units = 'years'
  } else {
    return true
  }

  let expected = requirement.meta[units]
  let actual = document[units] || []
  return !expected
    .map(v => actual.includes(v))
    .includes(false)
}

export const satisfiesVariantRegex =  (document, requirement) => {
  if (!requirement.variantRegex) return true
  return requirement.variantRegex.test(document.title)
}

export const satisfiesSourceCheck =  (document, requirement) => {
  if (!requirement.excludeSource) return true
  return requirement.excludeSource !== document.uploadSource
}

export const findDocumentForPill =  (documents, requirement) => {
  // If document available in meta data, use it. Currently provided when hydrating through appConfig modules
  if (requirement.meta?.document) {
    return requirement.meta.document
  }
  return documents.find(document => {
    // If we have a local id, use that to find the requirement.
    // We still use localId check for 'module' typeIdentifier to handle optimistic updates
    if (document._localId) {
      return document._localId === requirement.meta._localId
    }
    
    // If the requirement is a module, we need to skip the category check and just match on the localId.
    // Module document hydration is based on documentRequirements from API not the documents store values
    if (requirement.meta.typeIdentifier === 'module') {
      return null
    }

    if (requirement.meta.category === 'driverLicense') {
      return document.category === requirement.meta._localId
    }
    // otherwise, we need to match based on the document being the correct category/classification
    const categoryMatch = document.category === requirement.meta.category
    const timeMatch = satisfiesTimeClassification(document, requirement)
    const regexMatch = satisfiesVariantRegex(document, requirement)
    const sourceMatch = satisfiesSourceCheck(document, requirement)

    return categoryMatch && timeMatch && regexMatch && sourceMatch
  })
}

export const findPendingUploadForPill =  (pendingUploads, requirement) => {
  return pendingUploads.find(upload => {
    if (requirement) {
      return upload.meta._localId === requirement.meta._localId
    }
  })
}

export const findDocumentForRequirement =  (uploadedDocPills, docPill) => {
  return uploadedDocPills.find(
    (doc) =>
      doc.category == docPill.meta.category &&
      doc.years &&
      doc.years[0] == docPill.meta.years[0] && doc.title.indexOf('Additional Owner') == -1
  )
}

export const findAddOwnerDocumentForRequirement =  (uploadedDocPills, docPill) => {
  return uploadedDocPills.find(
    (doc) =>
      doc.category == docPill.meta.category &&
      doc.years &&
      doc.years[0] == docPill.meta.years[0]
      && doc.title == docPill.meta.formattedName + ".pdf"
  )
}

export const getBankStatementPills =  (monthsBack) => {
  const bankStatementArr = [];

  for (var i = 1; i <= monthsBack; i++) {
    bankStatementArr.push(subMonths(new Date(), i))
  }
  return bankStatementArr.map(month => {
    return {
      // formats date for document pill display
      name: format(month, 'MMMM yyyy'),
      accept: '.pdf',
      meta: {
        // formats date string to save in document.monthsString
        months: [format(month, 'MM/yyyy')],
        formattedName: 'BankStatement ' + format(month, 'MM-yy') + ' (M)',
        category: 'bankStatement',
        _localId: generateId()
      }
    }
  })
}

function isNumeric (input) {
  return /^\d+$/.test(input)
}

export const buildModuleDocumentPills = (documentCategory, fresh = false) => {
  const { categoryId, requirements } = documentCategory
  return requirements?.map(req => {
    const { displayName, formattedName, month, year, document, contactId } = req
    const meta = {
      formattedName: formattedName,
      document: fresh ? null : document ?? null,
      category: categoryId,
      typeIdentifier: 'module',
      _localId: generateId()
    }
    if (month) {
      meta.months = [month]
    }
    if (year) {
      meta.years = [year]
    }
    if (isNumeric(contactId)) {
      meta.contactId = contactId
    }
    return {
      name: displayName,
      accept: coreFileTypes.join(','),
      meta
    }
  })
}


export const generateId =  () => {
  return '_' + Math.random().toString(36).substr(2, 9)
}

export const formatMTDMeta =  (date) => {
  return {
    months: [(format(date, 'MM/yyyy'))],
    category: 'mtdTransactions',
    _localId: generateId(),
    formattedName: `MTD Transactions ${format(date, 'MM-dd-yy')}`
  }
}

export const checkHasDocument =  (documentsArray) => {
  return documentsArray.filter(doc => {
    if (!doc.error) {
      return get(doc, 'document.filename')
    }
  })
}

export const sbaTaxReturnPeriod =  () => {
  const currentDate = new Date()
  const currentYear = new Date().getFullYear()

  const date1 = new Date(currentYear + '-04-16')
  const date2 = new Date(currentYear + '-10-16')

  if (currentDate < date1) {
    return 'early'
  }

  if (currentDate >= date1 && currentDate < date2) {
    return 'mid'
  }

  return 'late'
}

export const sbaTaxReturnPeriodDocs =  (filingStatus) => {
  const period = sbaTaxReturnPeriod()

  const currentYear = new Date().getFullYear()
  const taxReturnYear1 = currentYear - 1
  const taxReturnYear2 = currentYear - 2
  const taxReturnYear3 = currentYear - 3

  let businessReturns = {}
  let personalReturns = {}

  const btr = 'businessTaxReturns'
  const btre = 'businessTaxReturnExtension'
  const ptr = 'personalTaxReturns'
  const ptre = 'personalTaxReturnExtension'

  if (period == 'early') {
    if (!filingStatus.business || !filingStatus.personal) {
      businessReturns[taxReturnYear2] = btr
      businessReturns[taxReturnYear3] = btr

      personalReturns[taxReturnYear2] = ptr
      personalReturns[taxReturnYear3] = ptr
    }
    if (filingStatus.business && filingStatus.personal) {
      businessReturns[taxReturnYear1] = btr
      businessReturns[taxReturnYear2] = btr

      personalReturns[taxReturnYear1] = ptr
      personalReturns[taxReturnYear2] = ptr
    }
  }

  if (period == 'mid') {
    if (!filingStatus.business && !filingStatus.personal) {
      businessReturns[taxReturnYear1] = btre
      businessReturns[taxReturnYear2] = btr
      businessReturns[taxReturnYear3] = btr

      personalReturns[taxReturnYear1] = ptre
      personalReturns[taxReturnYear2] = ptr
      personalReturns[taxReturnYear3] = ptr
    }
    if (!filingStatus.business && filingStatus.personal) {
      businessReturns[taxReturnYear1] = btre
      businessReturns[taxReturnYear2] = btr
      businessReturns[taxReturnYear3] = btr

      personalReturns[taxReturnYear1] = ptr
      personalReturns[taxReturnYear2] = ptr
      personalReturns[taxReturnYear3] = ptr
    }
    if (filingStatus.business && !filingStatus.personal) {
      businessReturns[taxReturnYear1] = btr
      businessReturns[taxReturnYear2] = btr
      businessReturns[taxReturnYear3] = btr

      personalReturns[taxReturnYear1] = ptre
      personalReturns[taxReturnYear2] = ptr
      personalReturns[taxReturnYear3] = ptr
    }
    if (filingStatus.business && filingStatus.personal) {
      businessReturns[taxReturnYear1] = btr
      businessReturns[taxReturnYear2] = btr

      personalReturns[taxReturnYear1] = ptr
      personalReturns[taxReturnYear2] = ptr
    }
  }

  if (period == 'late') {
    businessReturns[taxReturnYear1] = btr
    businessReturns[taxReturnYear2] = btr

    personalReturns[taxReturnYear1] = ptr
    personalReturns[taxReturnYear2] = ptr
  }

  return {'business': businessReturns, 'personal': personalReturns}
}

export const yearsRequired =  (docs) => {
  const years = Object.keys(docs)
  return years.sort().reverse()
}

export const additionalOwnerSbaTaxReturnPeriodDocs =  (additionalOwnerFilingStatus, primaryReturnsRequired) => {
  let personalReturns = {}
  const ptr = 'personalTaxReturns'

  const years = yearsRequired(primaryReturnsRequired['personal'])

  personalReturns[years[0]] = ptr
  personalReturns[years[1]] = ptr
  if (years.length > 2) {
    personalReturns[years[2]] = ptr
  }

  return {'business': {}, 'personal': personalReturns}
}

/**
 * Hydrates a pill config with borrower document upload info for FileSelect the component.
 * @param Object
 * @returns Array
 */
export const hydratePillConfig = ({ pendingUploads, documents }, pillConfig) => {
  let pendingUpload = findPendingUploadForPill(pendingUploads, pillConfig)
  const document = pendingUpload ? {
    filename: pendingUpload.filenames[0]
  } : findDocumentForPill(documents, pillConfig)

  return {
    ...pillConfig,
    document,
    progress: pendingUpload ? pendingUpload.progress : null,
    error: pendingUpload ? pendingUpload.error : null
  }
}

/**
 * Hydrates multiple pill configs for FileSelect values.
 *
 * @param String[]
 * @returns Array
 */
export const hydratedPillConfigsStipulations = ({ documents, pendingUploads, stipulations }) => {
  return Array.from(stipulations).map(stipulation => {

    stipulation.accept = additionalDocUploadFileTypes.join(',')
    stipulation.name = stipulation.displayName
    stipulation.meta = {
      category: stipulation.categoryId,
      _localId: stipulation.id,
      formattedName: stipulation.displayName
    }
    // Add to meta value, and meta expect plural version
    // Document requests already assumes year is provided with month.
    // This will need to change is month ever allowed by itself
    if (stipulation.month && stipulation.year) {
      stipulation.meta.months = [`${stipulation.month}/${stipulation.year}`]
    }
    else if (stipulation.year) {
      stipulation.meta.years = [`${stipulation.year}`]
    }
    return hydratePillConfig(
      { pendingUploads, documents },
      stipulation
    )
  })
}

export default {
  idFrontRegex,
  idBackRegex,
  totalFileType,
  dlFileTypes,
  coreFileTypes,
  additionalDocUploadFileTypes,
  buildModuleDocumentPills,
  satisfiesTimeClassification,
  satisfiesVariantRegex,
  satisfiesSourceCheck,
  findDocumentForPill,
  findPendingUploadForPill,
  findDocumentForRequirement,
  findAddOwnerDocumentForRequirement,
  getBankStatementPills,
  generateId,
  formatMTDMeta,
  checkHasDocument,
  sbaTaxReturnPeriod,
  sbaTaxReturnPeriodDocs,
  yearsRequired,
  additionalOwnerSbaTaxReturnPeriodDocs,
  hydratePillConfig,
  hydratedPillConfigsStipulations
}
