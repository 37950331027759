import { defineStore } from 'pinia'

import { ApplicationConfigService } from '~/libs/store-services/application-config-service'
import { ApplicationExperienceService } from '~/libs/store-services/application-experience-service'
import appNameFromRoute from '~/libs/app-name-from-route'

import { useBorrowerStore } from '~/store/borrower'
import { useRootStore } from '~/store/root'
import { useAffiliateCustomizationStore } from '~/store/affiliate-customization'
import { useExperimentsStore } from '~/store/experiments'
import env from '../libs/env'

export const useApplicationConfigStore = defineStore('applicationConfig', () => {
  const nuxtApp = useNuxtApp()
  const { $axios, $axiosWithRetry, $lendioCookies } = nuxtApp
  const route = useRoute()
  const applicationConfigService = new ApplicationConfigService($axios, $axiosWithRetry)
  const applicationExperienceService = new ApplicationExperienceService($axios, $axiosWithRetry)
  const borrowerStore = useBorrowerStore()
  const rootStore = useRootStore()
  const affiliateCustomizationStore = useAffiliateCustomizationStore()
  const experimentsStore = useExperimentsStore()

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
  */
  const applicationConfig = ref(null)
  const documentUploadRequestApplicationConfig = ref(null)
  const stale = ref(true)
  const preloadedPages = ref([])
  const preloadedIdentifier = ref('')
  const isBackEvent = ref(false)
  const completedModuleEventPaths = ref([])
  const navigation = ref(null)
  const submitSections = ref(null)
  const sbaAppConfigTestEnabled = ref(false)
  const finicityConnectTestEnabled = ref(false)
  const resumeExperienceAppEnabled = ref(false)


  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
  */

  const appIdentifierRoute = computed(() => {
    return `${affiliateCustomizationStore.preapprovalOnly ? 'preapproval' : 'marketplace'}-app`
  })

  const navItems = computed(() => {
    return navigation.value ?? []
  })

  const borrowerApplicationType = computed(() => {
    return applicationConfig.value?.borrowerApplicationType ?? null;
  })

  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
  */

  async function setIsBackEvent(isBack = false) {
    isBackEvent.value = isBack
  }

  function _setConfigValues({ _applicationConfig, _navigation, _submitSections, identifier }) {
    // update applicationConfig state
    applicationConfig.value = _applicationConfig
    submitSections.value = _submitSections
    navigation.value = _buildNavigation(_navigation)

    stale.value = false

    // cache values
    preloadedIdentifier.value = identifier
    preloadedPages.value = _applicationConfig.modules.reduce((pageSlugs, module) => {
      if (module.includesData) {
        pageSlugs.push(module.urlSlug)
      }
      return pageSlugs
    }, [])

    return applicationConfig.value
  }

  function _buildNavigation(navigation) {
    if (!navigation || !navigation.length) {
      return []
    }

    return navigation.reduce((acc, curr) => {
      // build nexted navigation sections if navLabelSection is defined
      if (curr?.navSectionLabel) {
        const section = acc.find(navItem => navItem.label === curr.navSectionLabel);
        if (section) {
          section.visibleChildren.push(curr)
          section.visited = section.visited || curr.visited;
          return acc;
        }

        acc.push({
          label: curr.navSectionLabel,
          type: 'section',
          url: curr.url,
          visited: curr.visited,
          visibleChildren: [curr],
          hiddenChildren: [],
        })
        return acc
      }

      acc.push(curr)
      return acc;
    }, [])
  }

  /**
   * @param resumeAppStrategy {string}  default | resumeProgress | resumeCompletedProgress | resumeLoanAppForced
   * @returns {Promise<{resumeUrl: *}>}
   */
  async function getResumeAppExperience(resumeAppStrategy= 'default') {
    const borrower = await borrowerStore.getBorrower()
    const borrowerId = borrower?.id
    const tenantId = affiliateCustomizationStore.tenantId
    const allowedResumeStrategies = ['default', 'resumeProgress', 'resumeCompletedProgress', 'resumeLoanAppForced']
    const type = allowedResumeStrategies.includes(resumeAppStrategy) ? resumeAppStrategy : 'default'

    console.log(`Resume app experience strategy: ${type}`)

    if (!borrowerId || !tenantId) {
      throw createError({
        statusCode: 'borrowerId and tenantId are required to fetch resume app experience',
        statusMessage: 400,
        fatal: true
      })
    }

    const { data, error } = await applicationConfigService.getResumeAppExperience({ borrowerId, tenantId, type })

    const resumeUrl = data?.resumeUrl
    const resource = data?.applicationExperienceResource
    const identifier = data?.identifier

    let errorMsg = 'Failed to fetch resume app experience'
    let errorStatus = 400

    if (error) {
      switch (error.status) {
        case 403:
          errorStatus = 403
          errorMsg = `${errorMsg} Access denied`
          break
        case 404:
          errorStatus = 404
          errorMsg = `${errorMsg} Page not found`
          break
        default:
          errorStatus = 500
          errorMsg = `${errorMsg} ${error.message}`
      }
    }

    if (error || !resumeUrl) {
      throw createError({
        statusCode: errorStatus,
        statusMessage: errorMsg,
        fatal: true
      })
    }

    if (resource && identifier && resource?.applicationConfig && resource?.navigation && resource?.submitSections) {
      _setConfigValues({
        _applicationConfig: resource.applicationConfig,
        _navigation: resource.navigation,
        _submitSections: resource.submitSections,
        identifier
      });
    }

    return { resumeUrl }
  }

  async function getLastInteractedResumeAppUrl({ isLogin }) {
    const borrower = await borrowerStore.getBorrower()
    const borrowerId = borrower?.id
    const tenantId = affiliateCustomizationStore.tenantId

    const payload = {
      borrowerId,
      isLogin,
      tenantId
    }

    const { data, error } = await applicationConfigService.getLastInteractedResumeAppUrl(payload)

    const _applicationConfig = data?.resource
    const identifier = _applicationConfig?.resumeIdentifier

    let errorMsg = 'Failed to fetch Last Interacted Application Config.'
    let errorStatus
    if (error && !identifier) {
      errorStatus = 500
      errorMsg = `${errorMsg} ${error.message}`
      throw createError({
        statusCode: errorStatus,
        statusMessage: errorMsg,
        fatal: true
      })
    }
    if (error) {
      switch (error.status) {
        case 403:
          errorStatus = 403
          errorMsg = `${errorMsg} Access denied: /${identifier}-app`
          break
        case 404:
          errorStatus = 404
          errorMsg = `${errorMsg} Page not found: /${identifier}-app`
          break
        default:
          errorStatus = 500
          errorMsg = `${errorMsg} ${error.message}`
      }
      throw createError({
        statusCode: errorStatus,
        statusMessage: errorMsg,
        fatal: true
      })
    }

    if (data.type === 'complete') {
      const _navigation = _applicationConfig?.navigation
      const _submitSections = _applicationConfig?.submitSections
      _setConfigValues({ _applicationConfig, _navigation, _submitSections, identifier })
    }

    return data
  }

  async function getByIdentifierAndSlug({ identifier, slug, direction }) {

    // return previously loaded value if we have it
    if (preloadedIdentifier.value !== identifier) {
      preloadedPages.value = []
    }

    if (!stale.value && !direction && this.checkSlugPreloaded(slug)) {
      return applicationConfig.value
    }

    const borrower = await borrowerStore.getBorrower()
    const borrowerId = borrower?.id
    const tenantId = affiliateCustomizationStore.tenantId
    const anonymousId = rootStore.anonymousId ?? rootStore.setAnonymousId()

    if (!borrowerId && !anonymousId) {
      log.warning('Failed to fetch Application Config', { message: 'No borrowerId or anonymousId', identifier, slug })
      return applicationConfig.value
    }

    const {appConfigIdentifier} = route.query

    if (appConfigIdentifier && borrower?.isTest === 1) {
      identifier = appConfigIdentifier
    }

    const payload = {
      anonymousId,
      identifier,
      borrowerId,
      tenantId,
      direction,
      slug,
    }

    await updateSbaExperienceTestEnabled()

    const useAppExperienceConfig = sbaAppConfigTestEnabled.value === true || $lendioCookies.get('appExperienceConfig') === true

    const { data, error } = useAppExperienceConfig
      ? await applicationExperienceService.getExperienceByIdentifierAndSlug(payload)
      : await applicationConfigService.getByIdentifierAndSlug(payload)

    let _applicationConfig, _navigation, _submitSections
    if (useAppExperienceConfig) {
      _applicationConfig = data.applicationConfig
    } else {
      _applicationConfig = data
    }
    _submitSections = data?.submitSections
    _navigation = data?.navigation


    if (error) {
      let errorStatus
      let errorMsg = 'Failed to fetch Application Config.'
      switch (error.status) {
        case 403:
          errorStatus = 403
          errorMsg = `${errorMsg} Access denied: /${identifier}-app`
          break
        case 404:
          errorStatus = 404
          errorMsg = `${errorMsg} Page not found: /${identifier}-app`
          break
        default:
          errorStatus = 500
          errorMsg = `${errorMsg} ${error.message}`
      }
      throw createError({
        statusCode: errorStatus,
        statusMessage: errorMsg,
        fatal: true
      })
    }

    if (!borrowerId) {
      // In an unauth'd scenario, if we're using localStorage inject answers if we have no borrowerId
    }

    return _setConfigValues({ _applicationConfig, _navigation, _submitSections, identifier })
  }

  async function getEmbeddedAppExperienceCompleteStatus() {
    const borrower = await borrowerStore.getBorrower()
    const borrowerId = borrower?.id

    const payload = {
      borrowerId
    }

    const useAppExperienceConfig = sbaAppConfigTestEnabled.value === true || $lendioCookies.get('appExperienceConfig') === true

    if (!useAppExperienceConfig) {
      return { applicationExperienceComplete: false }
    }

    const { data, error } = await applicationExperienceService.getEmbeddedAppExperienceCompleteStatus(payload)

    if (error || !data.hasOwnProperty('applicationExperienceComplete')) {
      log.warning('Embedded app experience complete status errored', { error })
      return { applicationExperienceComplete: false }
    }

    const applicationExperienceComplete = data.applicationExperienceComplete
    return { applicationExperienceComplete }
  }

  async function getDocumentUploadRequestApplicationConfig(documentUploadRequestInviteToken = null) {
    const borrowerId = borrowerStore.borrower?.id;

    const response = await $axios.get(
      borrowerId
        ? `${env('apiUrl')}/application-config/borrower/${borrowerId}/document-upload-request`
        : `${env('apiUrl')}/application-config/guest/document-upload-request`,
      {
        headers: !borrowerId && documentUploadRequestInviteToken ? {
          Authorization: `lendio-jwt ${documentUploadRequestInviteToken}`,
        } : null,
        params: { tenantId: affiliateCustomizationStore.tenantId }
      }
    )

    const configFromResponse = response?.data?.data ?? null

    if (configFromResponse) {
      configFromResponse.modules = configFromResponse?.modules?.map((module) => ({ ...module, completed: documentModuleComplete(module)}));
    }

    documentUploadRequestApplicationConfig.value = configFromResponse
    return documentUploadRequestApplicationConfig.value
  }

  function documentModuleComplete(module) {
    return module.moduleDocuments.every(moduleDocument => moduleDocument.requirements.every(req => req.document))
  }

  async function postApplicationEvent({ identifier, slug, eventType, path = null }) {
    if (!identifier || !eventType) {
      return false
    }

    if (eventType === 'moduleCompleted' && path && !checkPathForCompletedEvent(path)) {
      completedModuleEventPaths.value.push(path)
    }

    const borrower = await borrowerStore.getBorrower()
    const borrowerId = borrower?.id
    const anonymousId = rootStore.anonymousId ?? rootStore.setAnonymousId()

    if (!borrowerId && !anonymousId) {
      log.warning('Failed to save application event', { message: 'No borrowerId or anonymousId', eventType })
      return false
    }

    const payload = {
      identifier,
      slug,
      eventType,
      anonymousId,
      borrowerId
    }
    const { error } = await applicationConfigService.postApplicationEvent(payload)

    if (error) {
      log.warning('Failed to save application event', { message: error.message, eventType })
    }

    // Return boolean on success. No error = success
    return !error
  }

  function loadAnswersFromStorage() {
    if (borrowerStore.borrower || borrowerStore.borrowerId) {
      return {}
    }
    return applicationConfigService.loadAnswersFromStorage()
  }

  async function saveAnswers({ answers: _answers = null } = {}) {
    if (!Object.keys(_answers).length) {
      return
    }

    if (!borrowerStore.borrower || !borrowerStore.borrowerId) {
      const data = _answers
      applicationConfigService.mergeAnswersToStorage(data)

      return Object.keys(_answers)
    }

    const data = await applicationConfigService.saveAnswers({
      authenticated: Boolean(rootStore.authUser),
      answers: _answers,
      borrowerId: borrowerStore.borrowerId
    })
    stale.value = true

    if (data) {
      return Object.keys(_answers)
    }

    return []
  }

  async function updateSbaExperienceTestEnabled(){
    await _updateExperimentEnabledStoreVar({
      experimentState: sbaAppConfigTestEnabled,
      experimentDetails:[
        {
          name: 'SBA Experience Test',
          allowedAppNames: ['marketplace']
        },
        {
          name: 'IL SBA Experience Test',
          allowedAppNames: ['marketplace']
        },
        {
          name: 'EM SBA Experience Test',
          allowedAppNames: ['embedded', 'el']
        }
      ]
    })
  }

  async function updateFinicityConnectTestEnabled(){
    await _updateExperimentEnabledStoreVar({
      experimentState: finicityConnectTestEnabled,
      experimentDetails:[
        {
          name: 'Finicity Connect Test',
          allowedAppNames: ['marketplace']
        },
        {
          name: 'IL Finicity Connect Test',
          allowedAppNames: ['marketplace']
        },
        {
          name: 'EM Finicity Connect Test',
          allowedAppNames: ['embedded', 'el']
        }
      ]
    })
  }
  async function updateResumeExperienceAppEnabled(){
    await _updateExperimentEnabledStoreVar({
      experimentState: resumeExperienceAppEnabled,
      experimentDetails:[
        {
          name: 'Resume App Experience Test',
          allowedAppNames: ['marketplace']
        },
        {
          name: 'IL Resume App Experience Test',
          allowedAppNames: ['marketplace']
        },
        {
          name: 'EM Resume App Experience Test',
          allowedAppNames: ['embedded', 'el']
        }
      ]
    })
  }

  /**
   * experimentState is a ref() to track if experiment is enabled
   * experimentDetails contains one more experiments e.g. [{name: 'Experiment Name', allowedAppNames: ['marketplace', 'embedded']}]
   */
  async function _updateExperimentEnabledStoreVar({experimentState, experimentDetails = [], testGroup = 1} = {}) {
    if (!experimentState || experimentState?.value === undefined || !experimentDetails.length) {
      return
    }

    const user = rootStore.authUser
    if (!user) {
      experimentState.value = false
      return
    }

    const appName = appNameFromRoute(route)
    if (!appName) {
      experimentState.value = false
      return
    }

    const filteredExperimentNames = experimentDetails.reduce((returnArray, experiment) => {
      if (experiment.allowedAppNames.includes(appName)) {
        returnArray.push(experiment.name)
      }
      return returnArray
    }, [])
    if (!filteredExperimentNames.length) {
      experimentState.value = false
      return
    }

    await experimentsStore.fetchActiveExperimentsForCurrentUser()
    experimentState.value = experimentsStore.activeUserExperiments
      .some((userExperiment) => filteredExperimentNames.includes(userExperiment.name) && userExperiment.group === testGroup)
  }

  function checkSlugPreloaded(slug = null) {
    if (!slug) {
      return false
    }
    return preloadedPages.value.includes(slug)
  }

  function checkPathForCompletedEvent(path = null) {
    if (!path) {
      return false
    }
    return completedModuleEventPaths.value.includes(path)
  }

  function getModuleFromSlug(slug) {
    let slugModule
    const _modules = applicationConfig.value?.modules
    if (_modules && _modules.length && slug) {
     slugModule = _modules.find((module) => {
      return module.urlSlug === slug
     })
    }
    return slugModule ?? null
  }

  function setConfigStale(staleValue) {
    stale.value = staleValue
  }

  return {
    // STATE
    applicationConfig,
    completedModuleEventPaths,
    documentUploadRequestApplicationConfig,
    isBackEvent,
    navigation,
    navItems,
    preloadedIdentifier,
    preloadedPages,
    stale,
    submitSections,
    sbaAppConfigTestEnabled,
    finicityConnectTestEnabled,
    resumeExperienceAppEnabled,

    // GETTERS
    appIdentifierRoute,
    borrowerApplicationType,

    // ACTIONS
    checkSlugPreloaded,
    checkPathForCompletedEvent,
    getByIdentifierAndSlug,
    getEmbeddedAppExperienceCompleteStatus,
    getDocumentUploadRequestApplicationConfig,
    getLastInteractedResumeAppUrl,
    getModuleFromSlug,
    loadAnswersFromStorage,
    postApplicationEvent,
    saveAnswers,
    setConfigStale,
    setIsBackEvent,
    updateSbaExperienceTestEnabled,
    updateFinicityConnectTestEnabled,
    getResumeAppExperience,
    updateResumeExperienceAppEnabled,
  }
})
