import env from '~/libs/env'

export class ContactsService {
  constructor($axios, $axiosWithRetry) {
    this.$axios = $axios
    this.$axiosWithRetry = $axiosWithRetry
  }

  async upsertAdditionalOwnerContacts({ borrowerId, contacts }) {
    const url = `${env('apiUrl')}/borrower/${borrowerId}/additional-owner-contacts`
    let error = null

    const response = await this.$axios.post(url, {contacts})
      .catch(err => {
        error = err
      })

    return { data: response?.data?.data ?? null, error }
  }

  async deleteContact(id) {
    const url = `${env('apiUrl')}/contact/${id}`
    let error = null

    const response = await this.$axios.delete(url)
      .catch(err => {
        error = err
      })

    return { data: response?.data?.data ?? null, error }
  }
}
