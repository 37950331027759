import { defineStore } from 'pinia'
import { ref } from 'vue'
import get from 'lodash/get'
import env from '~/libs/env'

export const useDocusignStore = defineStore('docusign', () => {
  const nuxtApp = useNuxtApp();
  const { $axios } = nuxtApp;

  /*
  ███████ ████████  █████  ████████ ███████
  ██         ██    ██   ██    ██    ██
  ███████    ██    ███████    ██    █████
       ██    ██    ██   ██    ██    ██
  ███████    ██    ██   ██    ██    ███████
  STATE
  */

  const documentSigners = ref([]);

  /*
   ██████  ███████ ████████ ████████ ███████ ██████  ███████
  ██       ██         ██       ██    ██      ██   ██ ██
  ██   ███ █████      ██       ██    █████   ██████  ███████
  ██    ██ ██         ██       ██    ██      ██   ██      ██
   ██████  ███████    ██       ██    ███████ ██   ██ ███████
  GETTERS
  */

  /**
   * Whether all signers in the Docusign document template have completed
   * signing.
   */
  const allSignersHaveSigned = computed(() => {
    // No signers, so they can’t have all signed
    if (documentSigners.value.length === 0) {
      return false;
    }

    return documentSigners.value.every(
      (signer) => signer.status && signer.status === "completed"
    );
  });


  /*
   █████   ██████ ████████ ██  ██████  ███    ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ████   ██ ██
  ███████ ██         ██    ██ ██    ██ ██ ██  ██ ███████
  ██   ██ ██         ██    ██ ██    ██ ██  ██ ██      ██
  ██   ██  ██████    ██    ██  ██████  ██   ████ ███████
  ACTIONS
  ! - - Actions calling other actions in the same store must use `this.actionName(...)`
  ! - - If we do not use `this.actionName` it will not be properly mockable in tests.
  ! - - Computeds and refs will work fine, and should be called directly though.
  */

  /**
   * Fetch Docusign document signers for a given deal & document category
   *
   * @param {number} dealId - The deal ID for which to fetch document group’s signers.
   * @returns {Promise<void>}
   */
  async function getDocumentSigners(dealId, documentCategory = null) {
    const url = `${env('apiUrl')}/deal/${dealId}/docusign-envelope-signers`;
    const response = await $axios.get(url, { params: { category: documentCategory } }).catch((e) => {
      log.error(`Error fetching Docusign document signers: ${e.message}`, e);
      documentSigners.value = [];

      return;
    });

    documentSigners.value = get(response, 'data.data.signers');
  }

  return {
    // STATE
    documentSigners,

    // GETTERS
    allSignersHaveSigned,

    // ACTIONS
    getDocumentSigners,
  }
})
