import { bool } from '~/libs/format'

function getDebtScheduleQuestionTemplate() {
  return [
    {
      alias: 'lenderName',
      name: 'Lender name',
      value: '',
      type: 'string'
    },
    {
      alias: 'debtType',
      name: 'Type of debt',
      type: 'select',
      value: '',
      options: [
        {
          label: 'Credit Card',
          value: 'creditCard'
        },
        {
          label: 'Invoice Financing',
          value: 'invoiceFinancing'
        },
        {
          label: 'Line of Credit',
          value: 'lineOfCredit'
        },
        {
          label: 'Shareholder Note',
          value: 'shareholderNote'
        },
        {
          label: 'Term Loan',
          value: 'termLoan'
        }
      ],
    },
    {
      alias: 'loanStartDate',
      name: 'Loan Start Date',
      monthLabel: 'Loan start month',
      yearLabel: 'Loan start year',
      value: '',
      type: 'date-month-year',
      width: 'full'
    },
    {
      alias: 'isDebtConsolidation',
      name: 'Consolidating debt?',
      formatFnKey: 'bool',
      type: 'select',
      value: '',
      options: [
        {
          label: 'Yes',
          value: 'Yes'
        },
        {
          label: 'No',
          value: 'No'
        }
      ],
    },
    {
      alias: 'originalBalance',
      name: 'Original balance or Credit limit',
      value: '',
      type: 'currency',
      useMaska: true
    },
    {
      alias: 'term',
      name: 'Term (Months)',
      value: '',
      type: 'number'
    },
    {
      alias: 'interestRate',
      name: 'Interest rate',
      value: '',
      type: 'decimal'
    },
    {
      alias: 'currentBalance',
      name: 'Current balance',
      value: '',
      type: 'currency',
      useMaska: true
    },
    {
      alias: 'paymentAmount',
      name: 'Est. payment',
      value: '',
      type: 'currency',
      useMaska: true
    },
    {
      alias: 'isSBALoan',
      name: 'Is this an SBA?',
      formatFnKey: 'bool',
      type: 'select',
      value: '',
      options: [
        {
          label: 'Yes',
          value: 'Yes'
        },
        {
          label: 'No',
          value: 'No'
        }
      ],
    },
    {
      alias: 'paymentFrequency',
      name: 'Payment frequency',
      type: 'select',
      value: '',
      options: [
        {
          label: 'Daily',
          value: 'daily'
        },
        {
          label: 'Weekly',
          value: 'weekly'
        },
        {
          label: 'Every 2 Weeks',
          value: 'biWeekly'
        },
        {
          label: 'Monthly',
          value: 'monthly'
        }
      ],
    }
  ]
}

const formatMethods = {
  parse: {
    bool
  },
  construct: {
    bool: function(val) {
      const lowercase = val.toLowerCase()
      if(lowercase === 'yes') {
        return 1
      }
      if(lowercase === 'no') {
        return 0
      }
      return val
    },
  }
}

function parseDebtScheduleDBItems(dbArray) {
  return dbArray.map(dbItem => {
    const rowId = dbItem.id
    const formattedQuestions = getDebtScheduleQuestionTemplate().map(question => {
      const questionCopy = { ...question }
      const dbValue = dbItem[question.alias]
      if (dbValue || dbValue === 0) {
        const parseMethod = formatMethods.parse[question.formatFnKey]
        questionCopy.value =  parseMethod ? parseMethod(dbValue) : dbValue
      }
      return questionCopy
    });
    return {
      id: rowId,
      questions: formattedQuestions
    }
  });
}

function constructDebtScheduleItem(item, borrowerId) {
  const dbFormattedItem = { borrowerId }
  const { id, questions } = { ...item }
  if (id) {
    dbFormattedItem.id = id
  }
  questions.forEach(question => {
    const constructMethod = formatMethods.construct[question.formatFnKey]
    // Early return if no value
    if (!question.value && question.value !== 0) return
    dbFormattedItem[question.alias] = constructMethod ? constructMethod(question.value) : question.value
  })
  // Filter out answers if not required
  if (debtTypeFilteringObj[dbFormattedItem.debtType]){
    debtTypeFilteringObj[dbFormattedItem.debtType].forEach(key => {
      delete dbFormattedItem[key]
    })
  }
  return dbFormattedItem
}

const debtTypeFilteringObj = {
  creditCard: ['loanStartDate', 'term', 'isSBALoan'],
  shareholderNote: ['isSBALoan'],
  invoiceFinancing: ['isSBALoan']
}

export { getDebtScheduleQuestionTemplate, parseDebtScheduleDBItems, constructDebtScheduleItem, debtTypeFilteringObj }
